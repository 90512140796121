import {
    Drawer,
    DrawerBody,
    // DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    // DrawerCloseButton,
} from '@chakra-ui/react'
import {
    Box,
    Text,
    // Link,
    VStack,
    Code,
    Grid,
} from '@chakra-ui/react';

import { TOKEN_NAME } from '../config'

// const DrawerModal = (props) => {
export default function DrawerModal(props) {
    const { isOpen, onClose } = props;
    // console.log('props', props);
    // const [size, setSize] = React.useState('md')
    const size = 'full';
    // const { isOpen, onOpen, onClose } = useDisclosure()

    // const handleClick = (newSize) => {
    //     setSize(newSize)
    //     onOpen()
    // }

    // const sizes = ['xs', 'sm', 'md', 'lg', 'xl', 'full']

    return (
        <>
            {/* {sizes.map((size) => (
                <Button
                    onClick={() => handleClick(size)}
                    key={size}
                    m={4}
                >{`Open ${size} Drawer`}</Button>
            ))} */}

            <Drawer onClose={onClose} isOpen={isOpen} size={size}>
                <DrawerOverlay />
                <DrawerContent bg="rgba(0,0,0,0.85)">
                    <DrawerHeader textAlign={'center'}>Switch network</DrawerHeader>
                    <DrawerBody>
                        <Box textAlign="center" fontSize="xl">
                            <Grid minH="80vh" p={30}>
                                {/* <ColorModeSwitcher justifySelf="flex-end" /> */}
                                <VStack spacing={8}>
                                    <Text>
                                        {/* <Code fontSize="xl">Please switch to Ethereum network or Binance network to be able to use the {TOKEN_NAME} bridge</Code> */}
                                        <Code fontSize="xl">Please switch to Ethereum network to be able to use the {TOKEN_NAME} bridge</Code>
                                    </Text>
                                </VStack>
                            </Grid>
                        </Box>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}
// export default DrawerModal;