import { useEffect } from "react";
import { Button, Box, Text, Image } from "@chakra-ui/react";
// import { useEthers, useEtherBalance, useTokenBalance, shortenIfAddress } from "@usedapp/core";
import { useEthers, useTokenBalance, shortenIfAddress } from "@usedapp/core";
// import { formatEther } from "@ethersproject/units";
import {
  setAccount,
  setChainId,
  setTokenBalance,
  setValidChain
  // setSwapValue,
  // setSkiAvailibility
} from "../redux/actions/bridgeActions";
import { useDispatch } from "react-redux";
import { useDisclosure } from '@chakra-ui/react';
import DrawerModal from "./Drawer";
import * as CONF from '../config';
// import { addChain } from "../utilities/chainUtility";

export function GetAccount() {
  const { activateBrowserWallet, account, chainId } = useEthers();
  // const etherBalance = useEtherBalance(account);
  return {activateBrowserWallet, account, chainId};
}
// export function ACCOUNT(props: Props) {
//   return props.accounts[0];
// };
// const ConnectButton = React.memo(() => {
const ConnectButton = () => {
  // addChain(CONF.CHAINID_ETH);
  // addChain(CONF.CHAINID_BSC);
  // console.log('----------------Rendering ConnectButton----------------');
// export default function ConnectButton() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  // console.log('Rendering ConnectButton.js')
  const dispatch = useDispatch();

  const { activateBrowserWallet, account, chainId } = useEthers();
  const chainImg = chainId === CONF.CHAINID_ETH ? 'eth.png' : 'bsc.png';
  // dispatch(setAccount(account));
  // localStorage.account = account;
  // let tokenBalance = 0;
  const tokenAddress = chainId === CONF.CHAINID_BSC ? CONF.SKI_CONTRACT_BSC : CONF.SKI_CONTRACT_ETH;
  // console.log('tokenAddress', tokenAddress)
  const tokenBalance = useTokenBalance(tokenAddress, account)
  // const skiAvailability = useTokenBalance(tokenAddress, CONF.getBridgeContract(CONF.getReverseChainId(chainId)))

  // useEffect(() => {
  //   dispatch(setSkiAvailibility(skiAvailability));
  // }, [skiAvailability]);

  useEffect(() => {
    // console.log('---------------');
    // console.log('useEffect in ConnectButton to dispatch state');
    // console.log('provider', provider);
    // console.log('chainId', chainId);
    // activateBrowserWallet();
    // console.log('useEffect dispatch(setAccount(account))', account);
    // console.log('useEffect dispatch(setChainId(chainId)', chainId);
    if (chainId === CONF.CHAINID_ETH) {
      onClose();
      dispatch(setAccount(account));
      dispatch(setChainId(chainId));
      dispatch(setTokenBalance(tokenBalance));
      dispatch(setValidChain(true));
      // console.log('Close Modal');
    } else {
      dispatch(setValidChain(false));
      onOpen();
      // console.log('Open Modal');
    }
    // dispatch(setSwapValue(''));
  }, [activateBrowserWallet, dispatch, tokenBalance, account, chainId, onOpen, onClose]);

  // useEffect(() => {
  //   // activateBrowserWallet();
  //   // console.log('---------------');
  //   // console.log('useEffect dispatch(setTokenBalance(tokenBalance))', tokenBalance);
  //   dispatch(setTokenBalance(tokenBalance));
  // }, [dispatch, tokenBalance, account, chainId]);

  // if (chainId === 97) {
  // }
  // useEffect(() => {
  //   // activateBrowserWallet();
  //   console.log('---------------');
  //   console.log('useEffect dispatch(setAccount(account))', account);
  //   console.log('provider', provider);
  //   console.log('chainId', chainId);
  //   if (account) dispatch(setAccount(account));
  // }, [account]);

  // useEffect(() => {
  //   // activateBrowserWallet();
  //   console.log('---------------');
  //   console.log('useEffect chainId');
  //   console.log('chainId', chainId);
  //   if (chainId) dispatch(setChainId(chainId));
  //   if (chainId === 97) {
  //     onClose();
  //     console.log('Close Modal');
  //   } else {
  //     onOpen();
  //     console.log('Open Modal');
  //   }
  // }, [chainId]);

  useEffect(() => {
    activateBrowserWallet();
    // console.log('---------------');
    // console.log('useEffect activateBrowserWallet');
    // console.log('provider', provider);
    // console.log('chainId', chainId);
    // dispatch(setAccount(account));
  }, [activateBrowserWallet, account]);

  function handleConnectWallet() {
    // console.log('handleConnectWallet');
    activateBrowserWallet();
    // dispatch(setAccount(account));
  }

  const connectButton = account ? (
  // return account ? (
    <Box
      display="flex"
      alignItems="center"
      background="gray.700"
      borderRadius="xl"
      py="0"
    >
      <DrawerModal isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
      <Box px="1">
        <Image src={chainImg} width={'22px'}></Image>
        {/* <Text color="white" fontSize="xs">
          {tokenBalance && parseFloat(formatEther(tokenBalance)).toFixed(2)} SKI
        </Text> */}
      </Box>
      <Button
        // onClick={handleOpenModal}
        bg="gray.800"
        border="1px solid transparent"
        _hover={{
          border: "1px",
          borderStyle: "solid",
          borderColor: "blue.400",
          backgroundColor: "gray.700",
        }}
        borderRadius="xl"
        m="1px"
        px={3}
        height="38px"
      >
        <Text color="white" fontSize="xs" fontWeight="medium" mr="2">
          {/* {account &&
            `${account.slice(0, 6)}...${account.slice(
              account.length - 4,
              account.length
            )}`} */}
            { shortenIfAddress(account) }

            {/* { shortenIfAddress(account) ? console.log('success account', account) : console.log('success account', account) } */}
            {/* { async () => {
          await updateWallet(walletValue)
          // deactivate();
          // onClose()
        } */}
        </Text>
        {/* <Identicon /> */}
      </Button>
    </Box>
  ) : (
    <Button
      onClick={handleConnectWallet}
      // onChange={props.updateAccounts('ciccio')}
      bg="blue.800"
      color="blue.300"
      fontSize="xs"
      fontWeight="medium"
      borderRadius="xl"
      border="1px solid transparent"
      _hover={{
        borderColor: "blue.700",
        color: "blue.400",
      }}
      _active={{
        backgroundColor: "blue.800",
        borderColor: "blue.700",
      }}
    >
      <Text color="white" fontSize="xs" fontWeight="medium" mr="2">
        { !
        account && 'Connect metamask'}
      {/* {wUtils.hasWallet(user) ? wUtils.shortAddress(user) : 'Connect metamask'} */}
      </Text>
    </Button>
  );

  return connectButton
  // return { connectButton, account }
  // return [button, account];
}
// )
// console.log('ConnectButton account', ConnectButton().account);

export default ConnectButton;

// export {
//   ConnectButton,
//   // GetAccount
// }