// hooks/index.ts
// import { useSelector } from "react-redux";
import { ethers } from "ethers";
import { Contract } from '@usedapp/core/node_modules/@ethersproject/contracts'
import { useContractCall, useContractFunction } from "@usedapp/core";
import skiBridgeAbi from "../abi/SkiBridgeAbi.json";
import skiBridgeEthAbi from "../abi/SkiBridgeEthAbi.json"
import skiBscTokenAbi from "../abi/SkiBscAbi.json";
import skiEthTokenAbi from "../abi/SkiEthAbi.json";
// import { SKI_BRIDGE_CONTRACT_BSC } from "../config"
import * as CONF from "../config"

// const SkiBridgeContractInterface = new ethers.utils.Interface(skiBridgeAbi);

// const contract = new Contract(SKI_BRIDGE_CONTRACT_BSC, SkiBridgeContractInterface);
const bridgeContract = (chainId: number) => {
    // console.log('bridgeContract', CONF.getBridgeContract(chainId));
    return new Contract(CONF.getBridgeContract(chainId), bridgeInterface(chainId));
}
const bridgeInterface = (chainId: number) => {
    const bridgeAbi = chainId === CONF.CHAINID_ETH ? skiBridgeEthAbi : skiBridgeAbi;
    // console.log('bridgeAbi', bridgeAbi)
    return new ethers.utils.Interface(bridgeAbi);
}

export function useBridgeCall(methodName: string, chainId: number, arg: []) {
    // if (chainId < 0) return;
    try {
        const [result]: any = useContractCall({
            abi: bridgeInterface(chainId),
            address: CONF.getBridgeContract(chainId),
            method: methodName,
            args: arg,
        }) ?? [];
        // console.log('useBridgeCall result', result);
        return result;
    } catch (e) {
        console.log('useBridgeCall error', e);
        return null;
    }
}

export function useBridgeFunction(methodName: string, chainId: number) {
    // console.log('methodName', methodName);
    const { state, send } = useContractFunction(bridgeContract(chainId), methodName, {});
    return { state, send };
}


const tokenContract = (chainId: number) => {
    return new Contract(CONF.getTokenContract(chainId), tokenInterface(chainId));
}
const tokenInterface = (chainId: number) => {
    const tokenAbi = chainId === CONF.CHAINID_ETH ? skiEthTokenAbi : skiBscTokenAbi;
    const tokenInterface = new ethers.utils.Interface(tokenAbi);
    // console.log('tokenInterface', tokenInterface);
    return tokenInterface;
}

export function useTokenFunction(methodName: string, chainId: number) {
    const { state, send } = useContractFunction(tokenContract(chainId), methodName, {});
    return { state, send };
}

export function useTokenCall(methodName: string, chainId: number, arg: []) {
    // if (chainId < 0) return;
    const [result]: any = useContractCall({
        abi: tokenInterface(chainId),
        address: CONF.getTokenContract(chainId),
        method: methodName,
        args: arg,
    }) ?? [];
    // console.log('swapFee', swapFee);
    return result;
}

// export function useTokenAllowance() {
//     const [allowance] =
//         useContractCall({
//             abi: ethers.utils.Interface(skiBscTokenAbi),
//                 address: tokenAddress,
//                 method: 'allowance',
//                 args: [ownerAddress, spenderAddress],
//             }
//         ) ?? []
//     return allowance
// }
