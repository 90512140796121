// import { ethers } from 'ethers';
import { useSelector } from "react-redux";
import InputValueBox from './InputValueBox';
import SwapButton from "./SwapButton";
// import { swapChain } from "../utilities/chainUtility";
import * as CONF from '../config'
// import ConnectButton from "./ConnectButton";
import {
    // ChakraProvider,
    Box,
    VStack,
    // Button,
    // Tooltip,
    // Text,
    Alert,
    AlertIcon,
    AlertTitle,
    Flex
    // AlertDescription,
} from '@chakra-ui/react';

import { ArrowDownIcon } from '@chakra-ui/icons'
// import { Button } from "react-bootstrap";

export default function SwapBox() {
    const { fromChain, toChain } = useSelector((state) => state.bridge);
    const { swapMsg } = useSelector((state) => state.token);
    // console.log('swapMsg', swapMsg)

    // const handleSwapChain = async () => {
    //     swapChain(chainId);
    // }
    // const handleAddSKItoken = async () => {
    //     addSKItoken(chainId);
    // }

    return (
        <>
            <VStack>
                {swapMsg !== '' &&
                    <Box border={'0px'}><Alert status='error'>
                        <AlertIcon />
                        <AlertTitle>{swapMsg}</AlertTitle>
                        {/* <AlertDescription>Your Chakra experience may be degraded.</AlertDescription> */}
                    </Alert>
                    </Box>}
                <InputValueBox chainId={fromChain} title={CONF.getEnsName(fromChain)} />

                {/* <Box border={'0px'}>
                    <Tooltip label={'Switch from ' + CONF.getEnsName(fromChain) + ' to ' + CONF.getEnsName(toChain)} fontSize='md'>
                        <Button onClick={handleSwapChain} h={'40px'}>
                            <ArrowDownIcon w={6} h={6} color='gray' m={'2'} />
                            <Text fontSize={'12px'}>Switch network</Text>
                            <ArrowUpIcon w={6} h={6} color='gray' m={'2'} />
                        </Button>
                    </Tooltip>
                </Box> */}
                <Flex border={'0px'}>
                    <ArrowDownIcon w={6} h={6} color='gray' m={'2'} />
                    <ArrowDownIcon w={6} h={6} color='gray' m={'2'} />
                            {/* <Text fontSize={'12px'}>Switch network</Text> */}
                    <ArrowDownIcon w={6} h={6} color='gray' m={'2'} />
                </Flex>
                <InputValueBox chainId={toChain} title={CONF.getEnsName(toChain)} m={'2'} />
                <Box>
                    {/* {!account ? <ConnectButton /> :<SwapButton /> } */}
                    <SwapButton />
                </Box>
            </VStack>
        </>
    );
}