import { combineReducers } from "redux";
import { bridgeReducer, tokenReducer } from "./bridgeReducer";

const reducers = combineReducers({
  // allNft: nftReducer,
  bridge: bridgeReducer,
  token: tokenReducer,
  // nft: selectedNftReducer,
});

export default reducers;
