import { ActionTypes } from "../constants/action-types";
import * as CONF from '../../config';
import { formatEther } from "@ethersproject/units";

const intialState = {
  account: CONF.CONTRACT_OWNER,
  chainId: '',
  fromChain: '',
  toChain: '',
  tokenBalance: 0,
  fromBlock: 0,
  toBlock: 0,
  fromRangeBlock: 0,
  toRangeBlock: 0,
  validChain: false
};

const intialTokenState = {
  tokenBalance: CONF.ZERO_VALUE,
  fromValue: '',
  toValue: '',
  validSwap: false,
  swapMsg: '',
  skiAvailability: 0
};

export const bridgeReducer = (state = intialState, { type, payload }) => {
  // console.log(type);
  // console.log(payload);
  switch (type) {

    case ActionTypes.SET_ACCOUNT:
      // console.log('reducer SET_ACCOUNT payload', payload)
      const account = payload !== undefined ? payload : undefined;
      // console.log('reducer SET_ACCOUNT account', account)
      return { ...state, account: account };

    case ActionTypes.SET_CHAIN_ID:
      const fromChain = payload === CONF.CHAINID_BSC ? CONF.CHAINID_BSC : CONF.CHAINID_ETH;
      const toChain = payload === CONF.CHAINID_BSC ? CONF.CHAINID_ETH : CONF.CHAINID_BSC;
      return { ...state, chainId: payload, fromChain: fromChain, toChain: toChain };

    // case ActionTypes.SET_TOKEN_BALANCE:
    //   const tokenBalance = payload >= 0 ? parseFloat(formatEther(payload)).toFixed(2) : 0.00;
    //   return { ...state, tokenBalance: tokenBalance };

    case ActionTypes.SET_FROM_BLOCK:
      return { ...state, fromBlock: payload };

    case ActionTypes.SET_TO_BLOCK:
      return { ...state, toBlock: payload };

    case ActionTypes.SET_FROM_RANGE_BLOCK:
      return { ...state, fromRangeBlock: payload };

    case ActionTypes.SET_TO_RANGE_BLOCK:
      return { ...state, toRangeBlock: payload };

    case ActionTypes.SET_VALID_CHAIN:
      return { ...state, validChain: payload };

    // case ActionTypes.SET_SKI_AVAILABILITY:
    //   // console.log('payload', payload);
    //   const skiAvailability = payload >= 0 ? parseFloat(formatEther(payload)).toFixed(2) : CONF.ZERO_VALUE;
    //   // console.log('skiAvailability', skiAvailability);
    //   return { ...state, skiAvailability: skiAvailability };

    default:
      return state;
  }
};

export const tokenReducer = (state = intialTokenState, { type, payload }) => {
  // console.log(type);
  // console.log(payload);
  switch (type) {
    case ActionTypes.SET_TOKEN_BALANCE:
      const tokenBalance = payload >= 0 ? parseFloat(formatEther(payload)).toFixed(2) : CONF.ZERO_VALUE;
      return { ...state, tokenBalance: tokenBalance };

    case ActionTypes.SET_SWAP_VALUE:
      // console.log(parseFloat(payload) < parseFloat(state.skiAvailability));
      // payload = payload*1;
      // console.log('SET_SWAP_VALUE parseFloat(payload)', parseFloat(payload));
      const enoughtPoolSki = parseFloat(payload*1 <= 0 ? 0 : payload) <= parseFloat(state.skiAvailability) ? true : false
      const validSwap = parseFloat(state.tokenBalance) >= parseFloat(payload * 1) && parseFloat(payload * 1) > 0 && enoughtPoolSki ? true : false;
      const enoughtWalletSki = parseFloat(state.tokenBalance) >= parseFloat(payload * 1) ? true : false;
      // console.log('SET_SWAP_VALUE payload', payload * 1);
      // console.log('SET_SWAP_VALUE state.tokenBalance', state.tokenBalance);
      // console.log('SET_SWAP_VALUE state.skiAvailability', state.skiAvailability);
      // console.log('SET_SWAP_VALUE enoughtPoolSki', enoughtPoolSki);
      // console.log('SET_SWAP_VALUE validSwap', validSwap);
      // const swapMsg = validSwap === false ? 'Not enought funds' : '';
      // const swapMsg = validSwap === false ? enoughtPoolSki === true ? parseFloat(payload * 1) <= 0 ? 'Swap amount should be > 0' : 'Not enought funds' : 'Not enought funds on Bridge pool' : '';
      let swapMsg = enoughtPoolSki === true ? '' : 'Not enought funds on Bridge pool';
      swapMsg = enoughtWalletSki === true ? swapMsg : 'Not enought funds in your wallet';
      // parseFloat(payload) !== NaN ? 'Swap amount should be > 0' : ''
      const toValue = validSwap === false ? CONF.ZERO_VALUE : payload;
      return { ...state, fromValue: payload, toValue: toValue, validSwap: validSwap, swapMsg: swapMsg };
      // return { ...state, fromValue: parseFloat(payload).toFixed(2), toValue: parseFloat(toValue).toFixed(2), validSwap: validSwap, swapMsg: swapMsg };

    // case ActionTypes.SET_TO_VALUE:
    //   return { ...state, toValue: payload };

    case ActionTypes.SET_SKI_AVAILABILITY:
      // console.log('SET_SKI_AVAILABILITY payload', payload);
      const skiAvailability = payload >= 0 ? parseFloat(formatEther(payload)).toFixed(2) : CONF.ZERO_VALUE;
      // console.log('skiAvailability', skiAvailability);
      return { ...state, skiAvailability: skiAvailability };

    default:
      return state;
  }
};

// SET_TOKEN_BALANCE: "SET_TOKEN_BALANCE",
// SET_FROM_VALUE: "SET_FROM_VALUE",
// SET_TO_VALUE: "SET_TO_VALUE",
// SET_VALID_SWAP: "SET_VALID_SWAP",
// SET_MSG_SWAP: "SET_MSG_SWAP"

export const selectedNftReducer = (state = {}, { type, payload }) => {
  console.log(type);
  console.log(payload);
  switch (type) {
    case ActionTypes.SELECTED_NFT:
      return { ...state, ...payload };
    case ActionTypes.REMOVE_SELECTED_NFT:
      return {};
    default:
      return state;
  }
};

