export const ActionTypes = {
  SET_ACCOUNT: "SET_ACCOUNT",
  SET_CHAIN_ID: "SET_CHAIN_ID",
  SET_TOKEN_CONTRACT: "SET_TOKEN_CONTRACT",
  SET_MARKET_CONTRACT: "SET_MARKET_CONTRACT",
  SET_TOKEN_BALANCE: "SET_TOKEN_BALANCE",
  SET_FROM_VALUE: "SET_FROM_VALUE",
  SET_TO_VALUE: "SET_TO_VALUE",
  SET_VALID_SWAP: "SET_VALID_SWAP",
  SET_MSG_SWAP: "SET_MSG_SWAP",
  SET_SWAP_VALUE: "SET_SWAP_VALUE",
  SET_FROM_BLOCK: "SET_FROM_BLOCK",
  SET_TO_BLOCK: "SET_TO_BLOCK",
  SET_FROM_RANGE_BLOCK: "SET_FROM_RANGE_BLOCK",
  SET_TO_RANGE_BLOCK: "SET_TO_RANGE_BLOCK",
  SET_SKI_AVAILABILITY: "SET_SKI_AVAILIBILITY",
  SET_VALID_CHAIN: "SET_VALID_CHAIN"
};
