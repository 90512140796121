// import React from 'react';
// import React, { useEffect, useState } from 'react'
import { ethers } from 'ethers'
import { useEffect, useState } from 'react'
import {
    Box,
    chakra,
    // Tooltip,
    Button,
    Image,
    Spacer,
    keyframes,
    Flex,
    Text,
    Link
} from '@chakra-ui/react';
// import { Image, keyframes, Skeleton, usePrefersReducedMotion } from '@chakra-ui/react'
// import { ColorModeSwitcher } from '../../ColorModeSwitcher';
// import { Logo } from '../../Logo';
import ConnectButton from '../../components/ConnectButton';
import { useSelector, useDispatch } from "react-redux";
import SwapBox from '../../components/SwapBox';
// import { TransactionsList } from '../../components/SwapHistory';
import SwapHistory from '../../components/SwapHistory.tsx';
// import { ConfigProvider } from '@usedapp/core';
import * as CONF from '../../config';
import { addSKItoken } from '../../utilities/chainUtility'
import Footer from '../../components/Footer';
// import { addChain } from '../../utilities/chainUtility';
// import { GetSkiAvailability } from '../../utilities';
import { setSkiAvailibility } from "../../redux/actions/bridgeActions";



function Home() {
    // console.log('---------------------Rendering Home.js---------------------')
    const fade = keyframes({
        from: { opacity: 0.1 },
        to: { opacity: 1 },
    })
    const _animation = `${fade} 4 0.1s`
    const dispatch = useDispatch();
    const { account, chainId, validChain } = useSelector((state) => state.bridge);
    console.log('account', account)

    const { tokenBalance } = useSelector((state) => state.token);
    const [animation, setAnimation] = useState('');
    const [haveMetamask, sethaveMetamask] = useState(true);
    // const MINUTE_MS = 60000;
    const MINUTE_MS = 3000;
    // const interval = setInterval(() => {
    //     console.log('Logs every minute');
    //     setAnimation(`${fade} 3 0.1s`)
    // }, MINUTE_MS);
    let animated = false;
    let animatedTimes = 0;
    useEffect(() => {
        const interval = setInterval(() => {
            if (animatedTimes > 2) return;
            // console.log('Logs every minute', animation);
            if (animated === false) {
                setAnimation(_animation)
                animated = true;
                // console.log('setAnimation', animation);
            }
            else {
                setAnimation('')
                animated = false;
                // console.log('clearAnimation', animation);
            }
            animatedTimes++;
            // console.log('animatedTimes', animatedTimes);
        }, MINUTE_MS);
        const { ethereum } = window;
        const checkMetamaskAvailability = async () => {
            if (!ethereum) {
                sethaveMetamask(false);
            } else {
            sethaveMetamask(true);
            }
        };
        checkMetamaskAvailability();
        // return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])

    // console.log('Home chainId', chainId);
    // let history = () => { return <></>};
    // useEffect(() => {
    //     history = (account, chainId) => {
    //         console.log('history chainId', chainId);
    //         return <SwapHistory addressContract={CONF.getBridgeContract(chainId)} currentAccount={account} chainId={chainId} />
    //     }
    // }, [])
    // console.log('account', account)
    
    const handleAddSKItoken = async () => {
        addSKItoken(chainId);
    }


    useEffect(() => {
        (async function () {
            // console.log('HOME GetSkiAvailability chainId', chainId)
            if (!chainId && chainId !== 'undefined') return
            // else {
            //     console.log('HOME GetSkiAvailability chainId', chainId)
            //     GetSkiAvailability(chainId).then((result) => {
            //         console.log('HOME GetSkiAvailability availability', result.availability)
            //         dispatch(setSkiAvailibility(result.availability));
            //     })
            // }
            // if (chainId !== CONF.CHAINID_ETH && chainId !== CONF.CHAINID_BSC) {
            if (chainId !== CONF.CHAINID_ETH) {
                return;
            }
            // console.log('GetSkiAvailability chainId', chainId)
            const revChainId = CONF.getReverseChainId(chainId)
            // console.log('GetSkiAvailability revChainId', revChainId)
            const tokenAddress = CONF.getTokenContract(revChainId);
            // console.log('GetSkiAvailability tokenAddress', tokenAddress)
            const tokenAbi = CONF.getTokenAbi(revChainId);
            const abi = new ethers.utils.Interface(tokenAbi);
            const provider = CONF.getBridgeProvider(revChainId);
            const token = new ethers.Contract(tokenAddress, abi, provider);
            const bridgeAddress = CONF.getBridgeContract(revChainId);
            // console.log('GetSkiAvailability bridgeAddress', bridgeAddress)

            let availability = 0;

            try {
                await token.balanceOf(bridgeAddress).then((result) => {
                    // console.log('GetSkiAvailability result', result)
                    availability = result;
                    dispatch(setSkiAvailibility(availability));
                }).catch(err => {
                    console.log('Error getting SKI balance from bridge on '+CONF.getReverseChainName(chainId));
                });
            } catch (error) {
                console.log('Error getting SKI balance from bridge on '+CONF.getReverseChainName(chainId));
            }
            // console.log('GetSkiAvailability availability', availability)
        })();
    }, [chainId, tokenBalance])

    // useEffect(() => {
    //     if (!account) return;
    //     window.location.reload();
    // }, [account, chainId])


    // addChain(CONF.CHAINID_ETH);
    // addChain(CONF.CHAINID_BSC);

    return validChain && haveMetamask && account ? (
        // <ChakraProvider theme={theme}>
        <>
        <Box maxW="7xl" mx={'auto'} pt={0} px={{ base: 2, sm: 12, md: 17 }}>
            {/* <ColorModeSwitcher justifySelf="flex-end" /> */}
            <chakra.h1
                textAlign={'center'}
                fontSize={'4xl'}
                py={2}
                fontWeight={'bold'}>
                SKI Bridge
                <Spacer></Spacer>
                {/* <Tooltip label={'Add ' + CONF.TOKEN_NAME + ' token to metamask asset'}> */}
                <Button onClick={handleAddSKItoken} size={'xs'} mt={'-35px'} border={'1px'} borderColor={'orange.900'} animation={animation}>
                    {'Add ' + CONF.TOKEN_NAME + ' '} <Image src={CONF.getSkiIcon(chainId)} width={'18px'} borderRadius='full' ml={'5px'} mr={'5px'} /> {' to Metamask assets'}
                </Button>
                {/* SKI Availability: {skiAvailability} */}
                {/* </Tooltip> */}
            </chakra.h1>
            <SwapBox />
            {/* {history()} */}
            {account && chainId ? <SwapHistory addressContract={CONF.getBridgeContract(chainId)} currentAccount={account} chainId={chainId} tokenBalance={tokenBalance}/> : <></>}
        </Box>
        <Footer />
        </>
        // </ChakraProvider>
        // <Box textAlign="center" maxW="7xl" mx={'auto'} pt={0} px={{ base: 2, sm: 12, md: 17 }}>
        //     <chakra.h1
        //         textAlign={'center'}
        //         fontSize={'4xl'}
        //         py={10}
        //         fontWeight={'bold'}>
        //         Bridge
        //     </chakra.h1>
        //     <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: 5, lg: 8 }}>
        //         <Box textAlign="center" verticalAlign={'middle'} fontSize="xl">
        //             <Grid minH="80vh" p={3}>
        //                 <ColorModeSwitcher justifySelf="flex-end" />
        //             <VStack spacing={0}>
        //                 {/* <Logo h="40vmin" pointerEvents="none" /> */}
        //                 {/* <Text>Bridge</Text> */}
        //                 <InputValueBox title='BSC' />
        //                 <InputValueBox title='ETH' />
        //                 {/* <Text>
        //                     <Code fontSize="xl">{account && account.slice(0, 7)}...{account && account.slice(-4)}</Code>
        //                 </Text> */}
        //                 {/* <Link
        //                     color="teal.500"
        //                     href="https://chakra-ui.com"
        //                     fontSize="2xl"
        //                     target="_blank"
        //                     rel="noopener noreferrer"
        //                 >
        //                     Learn Chakra
        //                 </Link> */}
        //                 {/* <ConnectButton /> */}
        //                 </VStack>
        //             </Grid>
        //         </Box>
        //     </SimpleGrid>
        // </Box>
    ) : (
            !haveMetamask ? 
            <>
            <Flex
                p={50}
                w="full"
                alignItems="center"
                justifyContent="center"
            >
                <Box
                    w="xs"
                    bg="white"
                    _dark={{
                        bg: "gray.800",
                    }}
                    shadow="lg"
                    rounded="lg"
                    overflow="hidden"
                    mx="auto"
                >
                    <Image
                        w="full"
                        h={56}
                        fit="cover"
                        src="/metamask.png"
                        alt="avatar"
                    />

                    <Box py={5} textAlign="center">
                        <Text
                            display="block"
                            fontSize="2xl"
                            color="gray.800"
                            _dark={{
                                color: "white",
                            }}
                            fontWeight="bold"
                        >
                            You need <Link href='https://metamask.io/download/' isExternal>metamask</Link> browser compatible to be able to use this app.
                            {/* Please <Link href='https://metamask.io/download/' target={'blank'}>install metamask</Link> to be able to use this app. */}
                        </Text>
                        <Link href='https://metamask.io/download/' isExternal style={{ textDecoration: 'none' }}>
                            
                        <Button
                                mt={'15px'}
                        bgColor='orange'
                        color='black'
                        >
                            Install metamask
                            </Button>
                        </Link>
                    </Box>
                </Box>
            </Flex>
        {/* <chakra.h1
            textAlign={'center'}
            fontSize={'4xl'}
            py={2}
            fontWeight={'bold'}>
            You need a metamask browser compatible to be able to use this app.
            <Spacer></Spacer>
            <ConnectButton />
        </chakra.h1> */}
        <Footer />
        </>
        :
                <>
                    <Flex
                        p={50}
                        w="full"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Box
                            w="xs"
                            bg="white"
                            _dark={{
                                bg: "gray.800",
                            }}
                            shadow="lg"
                            rounded="lg"
                            overflow="hidden"
                            mx="auto"
                        >
                            <Image
                                w="full"
                                h={56}
                                fit="cover"
                                src="/metamask.png"
                                alt="avatar"
                            />

                            <Box py={5} textAlign="center">
                                <Text
                                    display="block"
                                    fontSize="2xl"
                                    color="gray.800"
                                    _dark={{
                                        color: "white",
                                    }}
                                    fontWeight="bold"
                                    mb={'12px'}
                                >
                                    You need to connect metamask to be able to use this app.
                                    {/* Please <Link href='https://metamask.io/download/' target={'blank'}>install metamask</Link> to be able to use this app. */}
                                </Text>
                                <ConnectButton />
                            </Box>
                        </Box>
                    </Flex>
                    {/* <chakra.h1
            textAlign={'center'}
            fontSize={'4xl'}
            py={2}
            fontWeight={'bold'}>
            You need a metamask browser compatible to be able to use this app.
            <Spacer></Spacer>
            <ConnectButton />
        </chakra.h1> */}
                    <Footer />
                </>
        );
}

export default Home;
