import React, { useEffect, useState } from 'react'
import {
    VStack,
    chakra,
    // Skeleton,
    // Button,
    // Tooltip
} from '@chakra-ui/react'
// import { ArrowRightIcon, RepeatIcon } from '@chakra-ui/icons'
import { ethers } from 'ethers'
import { Contract } from "ethers"
import * as CONF from '../config';
import { writeLocalStorageEvents, localStorageAccount, storedEvent } from '../utilities'
import SwapHistoryBox from './SwapHistoryBox'
import ProgressLoadingHistory from './ProgressLoadingHistory'
import { setFromBlock, setToBlock, setToRangeBlock, setFromRangeBlock } from "../redux/actions/bridgeActions";
import { useDispatch } from "react-redux";
// import Example from './Example';
// import { RepeatClockIcon } from '@chakra-ui/icons'
// import { sign } from 'crypto'


interface Props {
    addressContract: string,
    currentAccount: string | undefined,
    chainId: number,
    tokenBalance: string
}

declare let window: any

export default function SwapHistory(props: Props) {
    const chainId = props.chainId;
    const tokenBalance = props.tokenBalance;
    const bridgeAbi = CONF.getBridgeAbi(chainId);
    const abi = new ethers.utils.Interface(bridgeAbi);
    const addressContract = props.addressContract
    const currentAccount = props.currentAccount?.toLowerCase()
    const [swapped, setSwapped] = useState<any[]>([])
    const [bridged, setBridged] = useState<any[]>([])
    const [swapWaiting, setSwapWaiting] = useState<string>("true")
    const [swapLoading, setSwapLoading] = useState<string>("false")
    const [bridgeWaiting, setBridgeWaiting] = useState<string>("true")
    const [bridgeLoading, setBridgeLoading] = useState<string>("false")
    // const [loadTransaction, setLoadTransaction] = useState<string>("false")
    const dispatch = useDispatch();

    // const abortController = new AbortController();

    // const getSwappedHistory = async ({ signal }: any, loadNextHistory = "true") => {
    const getSwappedHistory = async (loadNextHistory = "true") => {
        // console.log('-----------------SWAPPED-------------------')
        // console.log('currentAccount', currentAccount);
        // console.log('chainId', chainId);
        // console.log('swapLoading', swapLoading);
        // console.log('swapWaiting', swapWaiting);
        if (!window.ethereum) return
        if (!currentAccount) return
        if (!chainId) return
        // if (swapLoading === "true") return;
        // if (swapWaiting === "false") return;
        if (swapWaiting === "false" || swapLoading === "true" || bridgeLoading === "true") return;
        // console.log('------------START SWAPPED-------------------')
        if (loadNextHistory === "true") {
            setBridgeLoading(status => ("true"));
            setBridgeWaiting(status => ("true"));
            // setSwapped([]);
        }
        setSwapWaiting(status => ("false"));
        setSwapLoading(status => ("true"));
        // setSwapped([]);
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const erc20: Contract = new ethers.Contract(addressContract, abi, provider)
        // const localStorageSwapped = localStorage.swapped !== undefined ?
        //     JSON.parse(localStorage.swapped)[chainId] !== undefined ?
        //         JSON.parse(localStorage.swapped)[chainId][currentAccount] !== undefined ? JSON.parse(localStorage.swapped)[chainId][currentAccount] : [] : [] : [];
        const localStorageSwapped = localStorageAccount("swapped", chainId, currentAccount)
        // console.log('localStorageSwapped', localStorageSwapped);
        const lastCheckBlock = localStorageSwapped.lastBlock !== undefined ? localStorageSwapped.lastBlock : CONF.getBridgeDeployedBlock(chainId);
        // console.log('getSwappedHistory lastCheckBlock', lastCheckBlock);
        // GetPastEvents({
        //     filter: erc20.filters.Swapped(currentAccount),
        //     provider: provider,
        //     contractAddress: addressContract,
        //     contractAbi: abi,
        //     fromBlock: lastCheckBlock,
        //     toBlock: "latest",
        //     chunkLimit: chainId === CONF.CHAINID_ETH ? 0 : 5000
        // }).then(({ events, errors, lastBlock }) => {
        //     // console.log('swapped getPastEvents events', events)
        //     // console.log('swapped localStorageSwapped', localStorageSwapped)
        //     // console.log('swapped localStorageSwapped.events', localStorageSwapped.events)
        //     const existingStorageEvents = localStorageSwapped.events ? localStorageSwapped.events : [];
        //     // console.log('swapped existingStorageEvents', existingStorageEvents)
        //     let existingEvents = events.length > 0 ? [...existingStorageEvents, ...events] : localStorageSwapped.events;
        //     // console.log('swapped localStorage existingEvents', existingEvents)
        //     const uEvents = uniqueEvents(existingEvents);
        //     const _exists = localStorage.swapped !== undefined ?
        //         JSON.parse(localStorage.swapped)[chainId] !== undefined ?
        //             JSON.parse(localStorage.swapped)[chainId] : {} : {};
        //     _exists[currentAccount] = {
        //         'lastBlock': lastBlock,
        //         'events': uEvents
        //     }
        //     const uAccount = uniqueAccount(_exists);
        //     // console.log('swapped localStorage _exists', _exists)
        //     var allEvents = localStorage.swapped !== undefined ? JSON.parse(localStorage.swapped) : {};
        //     allEvents[chainId] = uAccount;
        //     localStorage.swapped = JSON.stringify(allEvents);
        //     // console.log('localStorage.swapped', JSON.parse(localStorage.swapped))
        //     // console.log('uEvents', uEvents)
        //     setSwapped(uEvents);
        //     setEventLoading("false");
        // })

        ///////////////////////////////////////////////////////////////////
        let filter = erc20.filters.Swapped(currentAccount);
        // const firstTopic = [
        //     utils.id(`swap(uint256,uint32)`),
        //     utils.id(`bridge(uint256,address,uint32)`),
        // ]
        // const filter = {
        //     currentAccount, // address is the contract's addres...
        //     topics: [firstTopic],
        // }
        let contractAddress = addressContract;
        let contractAbi = abi;
        let fromBlock = lastCheckBlock;
        let toBlock = "latest";
        let chunkLimit = chainId === CONF.CHAINID_ETH ? 0 : 5000;
        // console.log('fromBlock', fromBlock)
        dispatch(setFromBlock(fromBlock));
        dispatch(setToBlock(fromBlock));
        dispatch(setFromRangeBlock(fromBlock))
        dispatch(setToRangeBlock(fromBlock))
        // console.log('fromBlock', fromBlock)
        // if (!provider) {
        //     return { events: [], errors: ["Assign chainId or provider"], lastBlock: null }
        // }
        // console.log('chunkLimit', chunkLimit);

        // const erc20: Contract = new ethers.Contract(addressContract, abi, provider)

        // const contract = new provider.eth.Contract(contractAbi, contractAddress)
        const contract = new ethers.Contract(contractAddress, contractAbi, provider)

        const fromBlockNumber = +fromBlock
        const toBlockNumber =
            toBlock === "latest" ? +(await provider.getBlockNumber()) : +toBlock;
        // console.log('toBlockNumber', toBlockNumber);
        dispatch(setToBlock(toBlockNumber));
        const totalBlocks = toBlockNumber - fromBlockNumber
        const chunks = []

        // setSwapWaiting(status => ("false"));
        // setSwapLoading(status => ("true"));
        if (chunkLimit > 0 && totalBlocks > chunkLimit) {
            const count = Math.ceil(totalBlocks / chunkLimit)
            let startingBlock = fromBlockNumber

            for (let index = 0; index < count; index++) {
                const fromRangeBlock = startingBlock
                const toRangeBlock =
                    index === count - 1 ? toBlockNumber : startingBlock + chunkLimit
                startingBlock = toRangeBlock + 1
                chunks.push({ fromBlock: fromRangeBlock, toBlock: toRangeBlock })
            }
        } else {
            chunks.push({ fromBlock: fromBlockNumber, toBlock: toBlockNumber })
        }

        const events = [] as any
        // const errors = []
        for (const chunk of chunks) {
            dispatch(setFromRangeBlock(chunk.fromBlock))
            dispatch(setToRangeBlock(chunk.toBlock))
            // console.log('swapped from - to', chunk.fromBlock + ' - ' + chunk.toBlock);
            try {
                await contract.queryFilter(filter, chunk.fromBlock, chunk.toBlock).then((chunkEvents) => {
                    // setSwapped(result);
                    // setSwapped([...swapped, result]);
                    if (chunkEvents?.length > 0) {
                        events.push(...chunkEvents)
                    }
                    const uEvents = writeLocalStorageEvents("swapped", chainId, currentAccount, events, chunk.toBlock);
                    setSwapped(uEvents);
                    // console.log('uEvents', uEvents)
                })
            } catch (error) {
                console.log('Error scanning blockchain from - to', chunk.fromBlock + ' - ' + chunk.toBlock);
                break;
            }
        }
        // // console.log('swapped getPastEvents events', events)
        // // console.log('swapped localStorageSwapped', localStorageSwapped)
        // // console.log('swapped localStorageSwapped.events', localStorageSwapped.events)
        // const existingStorageEvents = localStorageSwapped.events ? localStorageSwapped.events : [];
        // // console.log('swapped existingStorageEvents', existingStorageEvents)
        // let existingEvents = events.length > 0 ? [...existingStorageEvents, ...events] : localStorageSwapped.events;
        // // console.log('swapped localStorage existingEvents', existingEvents)
        // const uEvents = uniqueEvents(existingEvents);
        // const _exists = localStorage.swapped !== undefined ?
        //     JSON.parse(localStorage.swapped)[chainId] !== undefined ?
        //         JSON.parse(localStorage.swapped)[chainId] : {} : {};
        // _exists[currentAccount] = {
        //     'lastBlock': toBlockNumber,
        //     'events': uEvents
        // }
        // const uAccount = uniqueAccount(_exists);
        // // console.log('swapped localStorage _exists', _exists)
        // var allEvents = localStorage.swapped !== undefined ? JSON.parse(localStorage.swapped) : {};
        // allEvents[chainId] = uAccount;
        // localStorage.swapped = JSON.stringify(allEvents);

        // console.log('localStorage.swapped', JSON.parse(localStorage.swapped))
        // console.log('uEvents', uEvents)
        const uEvents = writeLocalStorageEvents("swapped", chainId, currentAccount, events, toBlockNumber);
        setSwapped(uEvents);
        setSwapLoading(status => ("false"));
        setSwapWaiting(status => ("true"));
        // console.log('------------------END SWAPPED-------------------')
        ///////////////////////////////////////////////////////////////////
        if (loadNextHistory === "true") getBridgedHistory("false");
        // if (loadNextHistory === "true") getBridgedHistory(signal, "false");
    }

    // const getBridgedHistory = async ({ signal }: any, loadNextHistory = "true") => {
    const getBridgedHistory = async(loadNextHistory = "true") => {
        // console.log('-----------------BRIDGED-------------------')
        // console.log('currentAccount', currentAccount);
        // console.log('chainId', chainId);
        // console.log('bridgeLoading', bridgeLoading);
        // console.log('bridgeWaiting', bridgeWaiting);
        if (!window.ethereum) return
        if (!currentAccount) return
        if (!chainId) return
        // if (bridgeLoading === "true") return;
        // if (bridgeWaiting === "false") return;
        if (bridgeWaiting === "false" || bridgeLoading === "true" || swapLoading === "true") return;
        // const bridgedChainId = CONF.getReverseChainId(chainId);
        const bridgedChainId = chainId;
        // console.log('------------START BRIDGED-------------------')
        if (loadNextHistory === "true") {
            setSwapLoading(status => ("true"));
            setSwapWaiting(status => ("true"));
            // setSwapped([]);
        }
        setBridgeWaiting(status => ("false"));
        setBridgeLoading(status => ("true"));
        // setBridged([]);
        // const provider = new ethers.providers.Web3Provider(window.ethereum)
        const provider = CONF.getBridgeProvider(bridgedChainId);
        // const erc20: Contract = new ethers.Contract(addressContract, abi, provider)
        const erc20: Contract = new ethers.Contract(CONF.getBridgeContract(bridgedChainId), abi, provider)
        // const localStorageBridged = localStorage.bridged !== undefined ?
        //     JSON.parse(localStorage.bridged)[bridgedChainId] !== undefined ?
        //         JSON.parse(localStorage.bridged)[bridgedChainId][currentAccount] !== undefined ? JSON.parse(localStorage.bridged)[bridgedChainId][currentAccount] : [] : [] : [];
        const localStorageBridged = localStorageAccount("bridged", bridgedChainId, currentAccount)
        // console.log('localStorageBridged', localStorageBridged);
        const lastCheckBlock = localStorageBridged.lastBlock !== undefined ? localStorageBridged.lastBlock : CONF.getBridgeDeployedBlock(bridgedChainId);
        // console.log('getBridgedHistory lastCheckBlock', lastCheckBlock);
        // GetPastEvents({
        //     filter: erc20.filters.Bridged(currentAccount),
        //     provider: provider,
        //     contractAddress: addressContract,
        //     contractAbi: abi,
        //     fromBlock: lastCheckBlock,
        //     toBlock: "latest",
        //     chunkLimit: bridgedChainId === CONF.CHAINID_ETH ? 0 : 5000
        // }).then(({ events, errors, lastBlock }) => {
        //     // console.log('bridged getPastEvents events', events)
        //     // console.log('bridged localStorageBridged', localStorageBridged)
        //     // console.log('bridged localStorageBridged.events', localStorageBridged.events)
        //     const existingStorageEvents = localStorageBridged.events ? localStorageBridged.events : [];
        //     // console.log('bridged existingStorageEvents', existingStorageEvents)
        //     let existingEvents = events.length > 0 ? [...existingStorageEvents, ...events] : localStorageBridged.events;
        //     // console.log('bridged localStorage existingEvents', existingEvents)
        //     const uEvents = uniqueEvents(existingEvents);
        //     const _exists = localStorage.bridged !== undefined ?
        //         JSON.parse(localStorage.bridged)[bridgedChainId] !== undefined ?
        //             JSON.parse(localStorage.bridged)[bridgedChainId] : {} : {};
        //     _exists[currentAccount] = {
        //         'lastBlock': lastBlock,
        //         'events': uEvents
        //     }
        //     const uAccount = uniqueAccount(_exists);
        //     // console.log('bridged localStorage _exists', _exists)
        //     var allEvents = localStorage.bridged !== undefined ? JSON.parse(localStorage.bridged) : {};
        //     allEvents[bridgedChainId] = uAccount;
        //     localStorage.bridged = JSON.stringify(allEvents);
        //     // console.log('localStorage.bridged', JSON.parse(localStorage.bridged))
        //     // console.log('uEvents', uEvents)
        //     setBridged(uEvents);
        //     setEventLoading("false");
        //     getSwappedHistory(lastCheckBlock);
        // })

        ///////////////////////////////////////////////////////////////////
        let filter = erc20.filters.Bridged(currentAccount);
        let contractAddress = addressContract;
        let contractAbi = abi;
        let fromBlock = lastCheckBlock;
        let toBlock = "latest";
        let chunkLimit = bridgedChainId === CONF.CHAINID_ETH ? 0 : 5000;
        // console.log('fromBlock', fromBlock)
        dispatch(setFromBlock(fromBlock));
        dispatch(setToBlock(fromBlock));
        dispatch(setFromRangeBlock(fromBlock))
        dispatch(setToRangeBlock(fromBlock))
        // console.log('fromBlock', fromBlock)
        // if (!provider) {
        //     return { events: [], errors: ["Assign chainId or provider"], lastBlock: null }
        // }
        // console.log('chunkLimit', chunkLimit);

        // const erc20: Contract = new ethers.Contract(addressContract, abi, provider)

        // const contract = new provider.eth.Contract(contractAbi, contractAddress)
        const contract = new ethers.Contract(contractAddress, contractAbi, provider)

        const fromBlockNumber = +fromBlock
        const toBlockNumber =
            toBlock === "latest" ? +(await provider.getBlockNumber()) : +toBlock;
        // console.log('toBlockNumber', toBlockNumber);
        dispatch(setToBlock(toBlockNumber));
        const totalBlocks = toBlockNumber - fromBlockNumber
        const chunks = []

        // setBridgeWaiting(status => ("false"));
        // setBridgeLoading(status => ("true"));
        if (chunkLimit > 0 && totalBlocks > chunkLimit) {
            const count = Math.ceil(totalBlocks / chunkLimit)
            let startingBlock = fromBlockNumber

            for (let index = 0; index < count; index++) {
                const fromRangeBlock = startingBlock
                const toRangeBlock =
                    index === count - 1 ? toBlockNumber : startingBlock + chunkLimit
                startingBlock = toRangeBlock + 1
                chunks.push({ fromBlock: fromRangeBlock, toBlock: toRangeBlock })
            }
        } else {
            chunks.push({ fromBlock: fromBlockNumber, toBlock: toBlockNumber })
        }

        const events = [] as any;
        // const errors = []
        for (const chunk of chunks) {
            dispatch(setFromRangeBlock(chunk.fromBlock))
            dispatch(setToRangeBlock(chunk.toBlock))
            // console.log('bridged from - to', chunk.fromBlock + ' - ' + chunk.toBlock);
            try {
                await contract.queryFilter(filter, chunk.fromBlock, chunk.toBlock).then((chunkEvents) => {
                    // setSwapped(result);
                    // setSwapped([...swapped, result]);
                    if (chunkEvents?.length > 0) {
                        events.push(...chunkEvents)
                    }
                    const uEvents = writeLocalStorageEvents("bridged", bridgedChainId, currentAccount, events, chunk.toBlock);
                    setBridged(uEvents);
                })
            } catch (error) {
                console.log('Error scanning blockchain from - to', chunk.fromBlock + ' - ' + chunk.toBlock);
                break;
            }
        }

        // // const newEvent = events !== undefined ? events : [];
        // const existingStorageEvents = localStorageBridged.events ? localStorageBridged.events : [];
        // // console.log('bridged existingStorageEvents', existingStorageEvents)
        // let existingEvents = events.length > 0 ? [...existingStorageEvents, ...events] : localStorageBridged.events;
        // // console.log('bridged localStorage existingEvents', existingEvents)
        // const uEvents = uniqueEvents(existingEvents);
        // const _exists = localStorage.bridged !== undefined ?
        //     JSON.parse(localStorage.bridged)[bridgedChainId] !== undefined ?
        //         JSON.parse(localStorage.bridged)[bridgedChainId] : {} : {};
        // _exists[currentAccount] = {
        //     'lastBlock': toBlockNumber,
        //     'events': uEvents
        // }
        // const uAccount = uniqueAccount(_exists);
        // // console.log('bridged localStorage _exists', _exists)
        // var allEvents = localStorage.bridged !== undefined ? JSON.parse(localStorage.bridged) : {};
        // allEvents[bridgedChainId] = uAccount;
        // localStorage.bridged = JSON.stringify(allEvents);

        // console.log('localStorage.bridged', JSON.parse(localStorage.bridged))
        // console.log('uEvents', uEvents)
        const uEvents = writeLocalStorageEvents("bridged", bridgedChainId, currentAccount, events, toBlockNumber);
        setBridged(uEvents);
        setBridgeLoading(status => ("false"));
        setBridgeWaiting(status => ("true"));
        // setSwapWaiting(status => ("true"));
        // setSwapLoading(status => ("true"));
        // console.log('----------------END BRIDGED-------------------')
        if (loadNextHistory === "true") getSwappedHistory("false");
        // if (loadNextHistory === "true") getSwappedHistory(signal, "false");
        ///////////////////////////////////////////////////////////////////

    }

    useEffect(() => {
        (async function() {
        if (!window.ethereum) return
        if (!currentAccount) return
        if (!chainId) return
        if (chainId !== CONF.CHAINID_ETH && chainId !== CONF.CHAINID_BSC) {
            // setSwapWaiting(status => ("true"));
            // setSwapLoading(status => ("false"));
            // setBridgeWaiting(status => ("true"));
            // setBridgeLoading(status => ("false")); 
            return;
        }
        // console.log('-------------------------------------------')
        // console.log('----------------useEffect------------------')
        // console.log('-------------------------------------------')

        // console.log('currentAccount', currentAccount);
        // console.log('chainId', chainId);
        // console.log('tokenBalance', tokenBalance);

        // setSwapWaiting(status => ("true"));
        // setSwapLoading(status => ("true"));
        // setBridgeWaiting(status => ("true"));
        // setBridgeLoading(status => ("true"));
        // setLoadTransaction(status => ("true"));
        // console.log('swapLoading', swapLoading);
        // console.log('swapWaiting', swapWaiting);
        // console.log('bridgeLoading', bridgeLoading);
        // console.log('bridgeWaiting', bridgeWaiting);
        // console.log('loadTransaction', loadTransaction);

        // const localStorageSwapped = localStorage.swapped !== undefined ?
        //     JSON.parse(localStorage.swapped)[chainId] !== undefined ?
        //         JSON.parse(localStorage.swapped)[chainId][currentAccount] !== undefined ? JSON.parse(localStorage.swapped)[chainId][currentAccount] : [] : [] : [];
        // // console.log('localStorageSwapped', localStorageSwapped);
        // const lastCheckBlock = localStorageSwapped.lastBlock !== undefined ? localStorageSwapped.lastBlock : CONF.getBridgeDeployedBlock(chainId);
        // console.log('useEffect lastCheckBlock', lastCheckBlock);
            // getBridgedHistory(lastCheckBlock);
            // const result = async () => {
            //     setSwapWaiting(status => ("true"));
            //     setSwapLoading(status => ("true"));
            //     setBridgeWaiting(status => ("true"));
            //     setBridgeLoading(status => ("true"));
            //     await getSwappedHistory(0)
            //     await getBridgedHistory(0, "false");
            // }
            // await result();
            // setSwapWaiting(status => ("true"));
            // setSwapLoading(status => ("true"));
            // setBridgeWaiting(status => ("true"));
            // setBridgeLoading(status => ("true"));
            // await getSwappedHistory(0)
            // await getBridgedHistory(0, "false");
            // const ac = new AbortController();
            // getSwappedHistory({ signal: ac.signal }, 0);
            // ac.abort(); // cancel the update
            // const uEvents = writeLocalStorageEvents("bridged", chainId, currentAccount, [], lastCheckBlock);
            setSwapped(storedEvent("swapped", chainId, currentAccount));
            setBridged(storedEvent("bridged", chainId, currentAccount));
            // getSwappedHistory("false").then(() => {
            //     getBridgedHistory("false");
            // })
            // getSwappedHistory();
            getSwappedHistory("false")
            // getBridgedHistory("false")
            // getSwappedHistory({ signal: abortController.signal });
        })();
    }, [currentAccount, chainId, tokenBalance])



    // useEffect(() => {
    //     // (async function () {
    //     //     console.log('RESET TRANSACTIONS LIST')
    //     //     setSwapped([]);
    //     //     setBridged([])
    //     // })();
    //     // abortController.abort()
    //     if (!currentAccount) return
    //     if (swapLoading === "true" || bridgeLoading === "true") return;
    //     window.location.reload();
    // }, [currentAccount, chainId])
    // }, [currentAccount, chainId])

    // useEffect(() => {
    //     // console.log('-------------------------------------------')
    //     // console.log('-------------------------------------------')
    //     // console.log('swapLoading', swapLoading);
    //     // console.log('swapWaiting', swapWaiting);
    //     // console.log('bridgeLoading', bridgeLoading);
    //     // console.log('bridgeWaiting', bridgeWaiting);
    //     // console.log('loadTransaction', loadTransaction);
    //     // console.log('-------------------------------------------')
    //     // console.log('-------------------------------------------')
    //     if (loadTransaction === "false") return;
    //     if (!currentAccount) return
    //     setLoadTransaction(status => ("false"));
    //     const localStorageSwapped = localStorage.swapped !== undefined ?
    //         JSON.parse(localStorage.swapped)[chainId] !== undefined ?
    //             JSON.parse(localStorage.swapped)[chainId][currentAccount] !== undefined ? JSON.parse(localStorage.swapped)[chainId][currentAccount] : [] : [] : [];
    //     // console.log('localStorageSwapped', localStorageSwapped);
    //     const lastCheckBlock = localStorageSwapped.lastBlock !== undefined ? localStorageSwapped.lastBlock : CONF.getBridgeDeployedBlock(chainId);
    //     // console.log('useEffect lastCheckBlock', lastCheckBlock);
    //     getBridgedHistory(lastCheckBlock);
    // }, [loadTransaction])

    // const handleRescanSwapHistory = () => {
    //     if (!currentAccount) return;
    //     const _exists = localStorage.swapped !== undefined ?
    //         JSON.parse(localStorage.swapped)[chainId] !== undefined ?
    //             JSON.parse(localStorage.swapped)[chainId] : {} : {};
    //     _exists[currentAccount] = {
    //         'lastBlock': CONF.getBridgeDeployedBlock(chainId),
    //         'events': []
    //     }
    //     const uAccount = uniqueAccount(_exists);
    //     // console.log('swapped localStorage _exists', _exists)
    //     var allEvents = localStorage.swapped !== undefined ? JSON.parse(localStorage.swapped) : {};
    //     allEvents[chainId] = uAccount;
    //     // console.log('allEvents', allEvents)
    //     localStorage.swapped = JSON.stringify(allEvents);
    //     // setSwapWaiting(status => ("true"))
    //     // setSwapLoading(status => ("true"));
    //     getSwappedHistory("false");
    //     // getSwappedHistory({ signal: abortController.signal }, "false");
    // }

    // const handleRefreshSwapHistory = () => {
    //     if (!currentAccount) return;
    //     getSwappedHistory("false");
    //     // getSwappedHistory({ signal: abortController.signal }, "false");
    // }

    // const handleRescanBridgeHistory = () => {
    //     if (!currentAccount) return;
    //     const _exists = localStorage.bridged !== undefined ?
    //         JSON.parse(localStorage.bridged)[chainId] !== undefined ?
    //             JSON.parse(localStorage.bridged)[chainId] : {} : {};
    //     _exists[currentAccount] = {
    //         'lastBlock': CONF.getBridgeDeployedBlock(chainId),
    //         'events': []
    //     }
    //     const uAccount = uniqueAccount(_exists);
    //     // console.log('swapped localStorage _exists', _exists)
    //     var allEvents = localStorage.bridged !== undefined ? JSON.parse(localStorage.bridged) : {};
    //     allEvents[chainId] = uAccount;
    //     // console.log('allEvents', allEvents)
    //     localStorage.bridged = JSON.stringify(allEvents);
    //     // setSwapWaiting(status => ("true"))
    //     // setSwapLoading(status => ("true"));
    //     getBridgedHistory("false");
    //     // getBridgedHistory({ signal: abortController.signal }, "false");
    // }

    // const handleRefreshBridgeHistory = () => {
    //     if (!currentAccount) return;
    //     getBridgedHistory("false");
    //     // getBridgedHistory({ signal: abortController.signal }, "false");
    // }

    return (
        <>
        {/* <Example /> */}
            <VStack pt={10} mb={'150px'}>
                {/* <Flex>
                    <Box p='1'>
                        <Image src={CONF.getChainImg(chainId)} width={'30px'} m={'2px'} alt={'to'} />
                    </Box>
                    <Box p='1'>
                        <ArrowRightIcon ml={'5px'} pr={'5px'} />
                    </Box>
                    <Box p='1'>
                        <Image src={CONF.getChainImg(CONF.getReverseChainId(chainId))} width={'30px'} m={'2px'} alt={'to'} />
                    </Box>
                </Flex> */}
                <chakra.h3
                    textAlign={'center'}
                    fontSize={'1xl'}
                    py={0}
                    fontWeight={'bold'}>
                    {CONF.TOKEN_NAME} token moved to {CONF.getReverseChainName(chainId)}
                    {/* {CONF.getChainName(chainId)} <ArrowRightIcon ml={'5px'} pr={'5px'} /> {CONF.getReverseChainName(chainId)} */}
                    {/* <Tooltip label={'Refresh transactions list'} fontSize='xs'>
                        <Button onClick={handleRefreshSwapHistory} size={'xs'} ml={'5px'}>
                            <RepeatIcon />
                        </Button>
                    </Tooltip> */}
                    {/* Transactions */}
                    {/* <Tooltip label={'Rescan all block (can take a while)'} fontSize='xs'>
                        <Button onClick={handleRescanSwapHistory} size={'xs'} ml={'5px'}>
                            <RepeatClockIcon />
                        </Button>
                    </Tooltip> */}
                </chakra.h3>
                {/* {swapped !== undefined ? swapped.length > 0 ?
                    <>
                        {swapped.slice(0).reverse().map((swap, key) => (
                            <React.Fragment key={key}>
                                <SwapHistoryBox item={swap} bridged={bridged} />
                            </React.Fragment>
                        ))}
                    </>
                    :
                    swapLoading === "true" ?
                        // <ProgressLoadingHistory waiting={swapWaiting} /> : swapWaiting === "true" ? <>Waiting blockchian scan</> : <>No transactions found </>
                        <ProgressLoadingHistory waiting={swapWaiting} /> : <>No transactions found </>
                    : <></>} */}
                {swapLoading === "true" ?
                    // <ProgressLoadingHistory waiting={swapWaiting} /> : swapWaiting === "true" ? <>Waiting blockchian scan</> : <>No transactions found </>
                    <ProgressLoadingHistory waiting={swapWaiting} /> : <></>}
                {swapped !== undefined ? swapped.length > 0 ?
                    <>
                        {swapped.slice(0).reverse().map((swap, key) => (
                            <React.Fragment key={key}>
                                <SwapHistoryBox item={swap} bridged={swapped} />
                            </React.Fragment>
                        ))}
                    </> : swapLoading === "false" ? <>No transactions found </> : <></> : <></>}
            </VStack>
            {/* <VStack pt={10} pb={10}>
                <chakra.h3
                    textAlign={'center'}
                    fontSize={'1xl'}
                    py={0}
                    fontWeight={'bold'}>
                    {CONF.TOKEN_NAME} token bridged from {CONF.getChainName(chainId)}
                </chakra.h3>
                {bridgeLoading === "true" ?
                    <ProgressLoadingHistory waiting={bridgeWaiting} /> : <></>}
                {bridged !== undefined ? bridged.length > 0 ?
                    <>
                        {bridged.slice(0).reverse().map((swap, key) => (
                            <React.Fragment key={key}>
                                <SwapHistoryBox item={swap} bridged={bridged} />
                            </React.Fragment>
                        ))}
                    </> : bridgeLoading === "false" ? <>No transactions found </> : <></> : <></>}
            </VStack> */}
        </>
    )
}