
import { ethers } from 'ethers'
// import { setFromBlock, setToBlock, setToRangeBlock, setFromRangeBlock } from "../redux/actions/bridgeActions";
// import { useDispatch } from "react-redux";
// import { Contract, getBlockNumber } from "ethers"
// import { formatEther } from "@ethersproject/units";
// import moment from 'moment'
import * as CONF from '../config'; 

export const GetSkiAvailability = async ({
    chainId
}) => {
    // const dispatch = useDispatch();
    try {
        // console.log('GetSkiAvailability chainId', chainId)
        const revChainId = CONF.getReverseChainId(chainId)
        // console.log('GetSkiAvailability revChainId', revChainId)
        const tokenAddress = CONF.getTokenContract(revChainId);
        // console.log('GetSkiAvailability tokenAddress', tokenAddress)
        const tokenAbi = CONF.getTokenAbi(revChainId);
        const abi = new ethers.utils.Interface(tokenAbi);
        const provider = CONF.getBridgeProvider(revChainId);
        const token = new ethers.Contract(tokenAddress, abi, provider);
        const bridgeAddress = CONF.getBridgeContract(revChainId);
        // console.log('GetSkiAvailability bridgeAddress', bridgeAddress)

        let availability = 0;
        await token.balanceOf(bridgeAddress).then((result) => {
            // console.log('GetSkiAvailability result', result)
            availability = result;
        })
        // console.log('GetSkiAvailability availability', availability)
        return { availability }
    } catch (error) {
        return { availability: 0 }
    }
}

export const GetPastEvents = async ({
    filter,
    provider,
    contractAddress,
    contractAbi,
    // event,
    fromBlock,
    // currentAccount,
    toBlock = "latest",
    chunkLimit = 0
}) => {
    // const dispatch = useDispatch();
    try {
        console.log('fromBlock', fromBlock)
        // dispatch(setFromBlock(fromBlock));
        // dispatch(setToBlock(toBlock));
        // console.log('fromBlock', fromBlock)
        if (!provider) {
            return { events: [], errors: ["Assign chainId or provider"], lastBlock: null }
        }
        // console.log('chunkLimit', chunkLimit);

        // const erc20: Contract = new ethers.Contract(addressContract, abi, provider)

        // const contract = new provider.eth.Contract(contractAbi, contractAddress)
        const contract = new ethers.Contract(contractAddress, contractAbi, provider)

        const fromBlockNumber = +fromBlock
        const toBlockNumber =
            toBlock === "latest" ? +(await provider.getBlockNumber()) : +toBlock;
        console.log('toBlockNumber', toBlockNumber);
        const totalBlocks = toBlockNumber - fromBlockNumber
        const chunks = []

        if (chunkLimit > 0 && totalBlocks > chunkLimit) {
            const count = Math.ceil(totalBlocks / chunkLimit)
            let startingBlock = fromBlockNumber

            for (let index = 0; index < count; index++) {
                const fromRangeBlock = startingBlock
                const toRangeBlock =
                    index === count - 1 ? toBlockNumber : startingBlock + chunkLimit
                startingBlock = toRangeBlock + 1
                chunks.push({ fromBlock: fromRangeBlock, toBlock: toRangeBlock })
            }
        } else {
            chunks.push({ fromBlock: fromBlockNumber, toBlock: toBlockNumber })
        }

        const events = []
        const errors = []
        for (const chunk of chunks) {
            // console.log('from - to', chunk.fromBlock + ' - ' + chunk.toBlock);
            // dispatch(setFromRangeBlock(chunk.fromBlock))
            // dispatch(setToRangeBlock(chunk.toBlock))
            await contract.queryFilter(filter, chunk.fromBlock, chunk.toBlock).then((chunkEvents) => {
                // setSwapped(result);
                // setSwapped([...swapped, result]);
                if (chunkEvents?.length > 0) {
                    events.push(...chunkEvents)
                }
            })
        }
        // console.log('Events', events);

        return { events, errors, lastBlock: toBlockNumber }
    } catch (error) {
        return { events: [], errors: [error], lastBlock: null }
    }
}

export const uniqueAccount = (_exists) => {
    if (!_exists) return {};
    // console.log('-----------------UNIQUE ACCOUNT-------------------')
    const aAccount = [];
    var uAccount = {};
    var keys = Object.keys(_exists);
    for (let i = 0; i < keys.length; i++) {
        var key = (keys[i]).toLowerCase();
        // if (_ev.indexOf(val[i].id) === -1) { // notice that there is a parenthesis after `id`.
        //     languages.push(val[i].id)
        // }
        // console.log('key', key);
        // console.log('_exists[key]', _exists[key]);
        // console.log('aAccount', aAccount);
        // console.log('aAccount.indexOf(key)', aAccount.indexOf(keys[i]));
        if (aAccount.indexOf(key) === -1) {
            aAccount.push(key);
            uAccount = {
                ...uAccount, [keys[i]]: _exists[keys[i]]
            }
            // console.log('ADDED', _exists[keys[i]]);
            //     uniqueEvents.push(existingEvents[i])
        }
    }
    // console.log('aAccount', aAccount);
    // console.log('uAccount', uAccount);
    // console.log('-------------END UNIQUE ACCOUNT-------------------')
    return uAccount;
}

export const uniqueEvents = (existingEvents) => {
    if (!existingEvents) return [];
    // console.log('-----------------UNIQUE EVENTS-------------------')
    const aEvent = [];
    const uEvents = [];
    for (let i = 0; i < existingEvents.length; i++) {
        // if (_ev.indexOf(val[i].id) === -1) { // notice that there is a parenthesis after `id`.
        //     languages.push(val[i].id)
        // }
        // console.log('existingEvents[i]', existingEvents[i]);
        if (aEvent.indexOf(existingEvents[i]['transactionHash']) === -1) {
            aEvent.push(existingEvents[i]['transactionHash']);
            uEvents.push(existingEvents[i])
        }
    }
    // console.log('aEvent', aEvent);
    // console.log('uEvents', uEvents);
    // console.log('-------------END UNIQUE EVENTS-------------------')
    return uEvents;
}

export const getBridgedItem = (bridged, nonce) => {
    if (!bridged) return undefined;
    var keys = Object.keys(bridged);
    for (let i = 0; i < keys.length; i++) {
        var key = keys[i];
        if (bridged[key]['args']['3'] === nonce) {
            return bridged[key]
        }
    }
    return undefined;
}

/*
kind can be swapped or bridged
*/
export const writeLocalStorageEvents = (kind, chainId, currentAccount, events, lastBlock) => {
    const storage = localStorageSpecific(kind);
    const accountStorage = localStorageAccount(kind, chainId, currentAccount);
    // console.log('swapped getPastEvents events', events)
    // console.log('swapped localStorageSwapped', localStorageSwapped)
    // console.log('swapped localStorageSwapped.events', localStorageSwapped.events)
    const existingStorageEvents = accountStorage.events ? accountStorage.events : [];
    // console.log(kind+' existingStorageEvents', existingStorageEvents)
    let existingEvents = events.length > 0 ? [...existingStorageEvents, ...events] : accountStorage.events;
    // console.log(kind+' localStorage existingEvents', existingEvents)
    const uEvents = uniqueEvents(existingEvents);
    const _exists = storage !== undefined ?
        JSON.parse(storage)[chainId] !== undefined ?
            JSON.parse(storage)[chainId] : {} : {};
    _exists[currentAccount] = {
        'lastBlock': lastBlock,
        'events': uEvents
    }
    const uAccount = uniqueAccount(_exists);
    // console.log(kind+' localStorage _exists', _exists)
    var allEvents = storage !== undefined ? JSON.parse(storage) : {};
    allEvents[chainId] = uAccount;
    if (kind === "swapped") {
        localStorage.swapped = JSON.stringify(allEvents);
    } else {
        localStorage.bridged = JSON.stringify(allEvents);
    }
    return uEvents;
}

export const localStorageAccount = (kind, chainId, currentAccount) => {
    if (kind === "swapped") {
        return localStorage.swapped !== undefined ?
            JSON.parse(localStorage.swapped)[chainId] !== undefined ?
                JSON.parse(localStorage.swapped)[chainId][currentAccount] !== undefined ? JSON.parse(localStorage.swapped)[chainId][currentAccount] : [] : [] : [];
    } else {
        return localStorage.bridged !== undefined ?
            JSON.parse(localStorage.bridged)[chainId] !== undefined ?
                JSON.parse(localStorage.bridged)[chainId][currentAccount] !== undefined ? JSON.parse(localStorage.bridged)[chainId][currentAccount] : [] : [] : [];
    }
}

export const existsEvents = (kind, chainId) => {
    const storage = localStorageSpecific(kind);
    return localStorage !== undefined ?
        JSON.parse(storage)[chainId] !== undefined ?
            JSON.parse(storage)[chainId] : {} : {};
}

export const localStorageSpecific = (kind) => {
    if (kind === "swapped") {
        return localStorage.swapped;
    } else {
        return localStorage.bridged
    }
}

export const storedEvent = (kind, chainId, currentAccount) => {
    const accountStorage = localStorageAccount(kind, chainId, currentAccount);
    const existingStorageEvents = accountStorage.events ? accountStorage.events : [];
    const uEvents = uniqueEvents(existingStorageEvents);
    return uEvents;
}

export default (GetPastEvents, uniqueAccount, uniqueEvents, getBridgedItem, storedEvent);