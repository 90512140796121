// import { ethers } from 'ethers';
import * as CONF from '../config'


export const swapChain = async (chainId) => {
    if (!chainId) return
    const swapChainId = CONF.getReverseChainId(chainId);
    // console.log('Swap from chain ' + chainId + ' to ' + swapChainId);
    if (window.ethereum.networkVersion !== swapChainId) {
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${Number(swapChainId).toString(16)}` }],
            });
        } catch (err) {
            console.log('error')
            // This error code indicates that the chain has not been added to MetaMask.
            // if (err.code === -32603) {
            try {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainName: CONF.getChainName(swapChainId),
                            // chainId: "0x" + swapChainId,
                            chainId: `0x${Number(swapChainId).toString(16)}`,
                            nativeCurrency: { name: CONF.getEnsName(swapChainId), decimals: 18, symbol: CONF.getCurrencyName(swapChainId) },
                            rpcUrls: [CONF.getRpcMetamaskUrl(swapChainId)],
                            blockExplorerUrls: [CONF.getChainExplorer(swapChainId)]
                        },
                    ],
                });
                // }
            } catch (err) {

            }
        }
    }
}

export const addChain = async (chainId) => {
    if (!chainId) return
    // const swapChainId = CONF.getReverseChainId(chainId);
    // console.log('Swap from chain ' + chainId + ' to ' + swapChainId);
    if (window.ethereum.networkVersion !== chainId) {
            try {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainName: CONF.getChainName(chainId),
                            // chainId: "0x" + swapChainId,
                            chainId: `0x${Number(chainId).toString(16)}`,
                            nativeCurrency: { name: CONF.getEnsName(chainId), decimals: 18, symbol: CONF.getCurrencyName(chainId) },
                            rpcUrls: [CONF.getRpcMetamaskUrl(chainId)],
                            blockExplorerUrls: [CONF.getChainExplorer(chainId)]
                        },
                    ],
                })
                // .then(() => {
                //     addSKItoken(chainId);
                // })
                ;
            } catch (err) {
                console.log('Err on adding ' + CONF.getChainName(chainId) +' blockchain')
            }
        }
    // }
}

export const addSKItoken = async (chainId) => {
    if (!chainId) return;
    await window.ethereum
        .request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options: {
                    address: CONF.getTokenContract(chainId),
                    symbol: CONF.TOKEN_NAME,
                    decimals: 18,
                    image: CONF.getSkiMetamaskIcon(chainId),
                },
            },
        })
        .then((success) => {
            if (success) {
                console.log(CONF.TOKEN_NAME+' successfully added to wallet!');
            } else {
                throw new Error('Something went wrong.');
            }
        })
        .catch(console.error);
}