import React from "react";
// import Clock from "./Clock";
// import { ReactNode } from 'react';
import {
    Box,
    Flex,
    // Avatar,
    Link,
    // Button,
    // Menu,
    // MenuButton,
    // MenuList,
    // MenuItem,
    // MenuDivider,
    // useDisclosure,
    useColorModeValue,
    Stack,
    // useColorMode,
    // Center,
    Image,
    // Text
} from '@chakra-ui/react';
// import { MoonIcon, SunIcon } from '@chakra-ui/icons';

import ConnectButton from '../../components/ConnectButton';
// import Web3box from "./Web3box";
// import {ConnectButton, GetAccount} from "./ConnectButton";
// import ConnectButton, { GetAccount } from "./ConnectButton";
// import ConnectButton from "./ConnectButton";
// import WalletButton from "./WalletButton";
// import UsdSkiRate from "./UsdSkiRate";
// import AccountModal from "./AccountModal";
// import { userSuccess } from '../utils/WalletUtils'
// import {ConnButton, account} from "./ConnectButton";
// import ConnectButton2 from "./ConnectButton2";

// const account =  GetAccount;
// console.log('GetAccount', account)

// const NavLink = ({ children }: { children: ReactNode }) => (
//   <Link
//     px={2}
//     py={1}
//     rounded={'md'}
//     _hover={{
//       textDecoration: 'none',
//       bg: useColorModeValue('gray.600', 'gray.700'),
//     }}
//     href={'#'}>
//     {children}
//   </Link>
// );

// const accounts = async () => {
//   console.log('setAccount');
//   var accounts = [];
//   // const accounts = await window.ethereum.request({ method: 'eth_accounts' });
//   accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
//   // const web3 = this.state.web3;
//   // var accounts = await web3.eth.getAccounts();
//   console.log('accounts', accounts);
//   // this.setState({accounts});
//   // this.setState({accounts: accounts});
//   // await this.hasVoted();
//   // await this.setOwner();
//   // if (accounts.lenght > 0) this.setState({ loading: false});

//   // return this.setOwner();
//   return accounts;
// }

const Header = React.memo(props => {
// const Header = (props) => {
    // const account = ConnectButton.account;
    // const { account } = GetAccount();
    // console.log('account', account);
    // props.updateAccounts(account);
    // const ConnButton = ConnectButton.connectButton;
    // console.log('ConnButton', ConnButton);
    // const { ConnButton, account } = ConnectButton();
    // console.log('Header props.user', props.user);
    // const { colorMode, toggleColorMode } = useColorMode();
    // const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <>
            <Box
                bgGradient={useColorModeValue('linear(to-r, yellow.400, cyan.400)', 'linear(to-r, yellow.500, cyan.500)')}
                px={4}
          h='100px'
          // pos={'fixed'}
          // left={0}
          // top={0}
          // right={0}
          // mb={'150px'}
          >
                <Flex h='100px' alignItems={'center'} justifyContent={'space-between'}>
                    <Box >
                        <Link href='/'>
                        <Image src='skillchain-logo-blue.png' alt='Skillchain bridge' w={40} />
                        </Link>
                    </Box>
                    {/* <Clock /> */}

                    <Flex alignItems={'center'}>
                        <Stack direction={'row'} spacing={7}>
                            <ConnectButton />
                            {/* <Web3box props={props.props} updateAccounts={props.updateAccounts}/> */}
                            {/* <ConnButton updateAccounts={props.updateAccounts} accounts={props.accounts} account={props.account} /> */}
                            {/* {userSuccess(props.user) ?
                                props.hasMetamask ?
                                    <ConnectButton user={props.user} updateWallet={props.updateWallet} />
                                    :
                                    <>
                                        <WalletButton user={props.user} handleOpenModal={onOpen} />
                                        <AccountModal isOpen={isOpen} onClose={onClose} updateWallet={props.updateWallet} state={props.state} />
                                    </>
                                :
                                <></>
                            } */}
                            {/* {!props.hasWallet && !props.hasMetamask ? <WalletButton user={props.user} /> : <></>  } */}
                            {/* <ConnectButton2 updateAccounts={props.updateAccounts} accounts={props.accounts} account={props.account} /> */}
                            {/* <Button onClick={toggleColorMode}>
                {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
              </Button> */}
                            {/* <UsdSkiRate state={props.state}/> */}

                            {/* <Menu>
                <MenuButton
                  as={Button}
                  rounded={'full'}
                  variant={'link'}
                  cursor={'pointer'}
                  minW={0}>
                  <Avatar
                    size={'sm'}
                    src={'https://avatars.dicebear.com/api/male/username.svg'}
                  />
                </MenuButton>
                <MenuList alignItems={'center'}>
                  <br />
                  <Center>
                    <Avatar
                      size={'2xl'}
                      src={'https://avatars.dicebear.com/api/male/username.svg'}
                    />
                  </Center>
                  <br />
                  <Center>
                    <p>{ props.user.name }</p>
                  </Center>
                  <br />
                  <MenuDivider />
                  <MenuItem>Your Servers</MenuItem>
                  <MenuItem>Account Settings</MenuItem>
                  <MenuItem>Logout</MenuItem>
                </MenuList>
              </Menu> */}
                        </Stack>
                    </Flex>
                </Flex>
            </Box>
        </>
    );
});

export default Header;