import { ColorModeScript } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import reportWebVitals from './reportWebVitals';
// import * as serviceWorker from './serviceWorker';
import store from "./redux/store";
import { Provider } from "react-redux";
import { DAppProvider } from "@usedapp/core";
import { ChakraProvider } from '@chakra-ui/react';
import { myTheme } from './theme'

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <DAppProvider config={{}}>
        <ColorModeScript />
        <ChakraProvider theme={myTheme}>
          <App />
        </ChakraProvider>
      </DAppProvider>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
