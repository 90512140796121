import { ethers } from 'ethers'
import skiBridgeAbi from "./abi/SkiBridgeAbi.json";
import skiBridgeEthAbi from "./abi/SkiBridgeEthAbi.json"
import skiTokenAbi from "./abi/SkiBscAbi.json";
import skiTokenEthAbi from "./abi/SkiEthAbi.json"

const TEST_ENV = false;

const ETH_ENS = 'ETH';
const BSC_ENS = 'BSC';

const ETH_CHAINCODE = '0x4554480000000000000000000000000000000000000000000000000000000000';
// const BSC_CHAINCODE = '0x4253430000000000000000000000000000000000000000000000000000000000';

const ETH_EXPLORER = TEST_ENV === true ? 'https://rinkeby.etherscan.io/' : 'https://etherscan.io/';
const BSC_EXPLORER = TEST_ENV === true ? 'https://testnet.bscscan.com/' : 'https://bscscan.com/';

const ETH_RPC = TEST_ENV === true ? 'rinkeby' : 'homestead';
const BSC_RPC = TEST_ENV === true ? 'https://data-seed-prebsc-1-s1.binance.org:8545/' : 'https://bsc-dataseed.binance.org/';

const ETH_METAMASK_RPC = TEST_ENV === true ? 'https://rinkeby.infura.io/v3/' : 'https://mainnet.infura.io/v3/';
const BSC_METAMASK_RPC = TEST_ENV === true ? 'https://data-seed-prebsc-1-s1.binance.org:8545/' : 'https://bsc-dataseed.binance.org/';

const ETH_SKI_ICON = 'ski.png'
const BSC_SKI_ICON = 'ski.png'

const ETH_METAMASK_SKI_ICON = 'https://etherscan.io/token/images/skillchain_32.png'
const BSC_METAMASK__SKI_ICON = 'https://bscscan.com/token/images/skillchain_32.png?v=2'


export const ETH_CURRENCY = 'ETH';
export const BSC_CURRENCY = 'BNB';
export const TOKEN_NAME = 'SKI';

//Ropsten token 0x49f590AbD489A87F2A0EacbE329B6DA9fF2D75A1
export const SKI_CONTRACT_ETH = TEST_ENV === true ? '0x19CE9e9c46002307c8bf3E95AB09f1CfF230035b' : '0x996Dc5dfc819408Dd98Cd92c9a76f64b0738Dc3D';
// export const SKI_CONTRACT_ETH = TEST_ENV === true ? '0x49f590AbD489A87F2A0EacbE329B6DA9fF2D75A1' : '0x996Dc5dfc819408Dd98Cd92c9a76f64b0738Dc3D';
export const SKI_CONTRACT_BSC = TEST_ENV === true ? '0x701E9FCCF9f2AeBB0cBA07c969690681171FFeb2' : '0x7f692c05058f1c77c87413a0591c7a237090da00';
// Ropsten bridge 0x822d1b1643bf1cd923adf64b5962bf6a3d122788
// export const SKI_BRIDGE_CONTRACT_ETH = TEST_ENV === true ? '0x49f590AbD489A87F2A0EacbE329B6DA9fF2D75A1' : '...';
export const SKI_BRIDGE_CONTRACT_ETH = TEST_ENV === true ? '0xf5cDe023232E546359a89AE905016Aa8D559759C' : '0xc0600f14f825130ef2094f583a2eb236c22dd653';
export const SKI_BRIDGE_CONTRACT_BSC = TEST_ENV === true ? '0xf4DA9557043eAb771912EEB113a2375d6244aE0A' : '0xC0600f14F825130ef2094F583a2Eb236C22dd653';
export const CONTRACT_OWNER = TEST_ENV === true ? '0x2193251A17ad45d5a42086C8395d1933F3966148' : '0x2193251A17ad45d5a42086C8395d1933F3966148';
export const ETH_BRIDGE_DEPLOYED_BLOCK = TEST_ENV === true ? 10427579 : 14659441;
export const BSC_BRIDGE_DEPLOYED_BLOCK = TEST_ENV === true ? 18065664 : 17278696;
export const CHAINID_ETH = TEST_ENV === true ? 4 : 1;
export const CHAINNAME_ETH = TEST_ENV === true ? 'Rinkeby Test Network' : 'Ethereum Mainnet';
export const CHAINID_BSC = TEST_ENV === true ? 97 : 56;
export const CHAINNAME_BSC = TEST_ENV === true ? 'Smart Chain - Testnet' : 'Binance Smart Chain';
// export const CHAINID = TEST_ENV === true ? [4, 97] : [1, 56];
export const CHAINID = [CHAINID_ETH, CHAINID_BSC];

export const getEnsName = (chainId) => {
    return (chainId === CHAINID_ETH) ? ETH_ENS : BSC_ENS
}

export const getCurrencyName = (chainId) => {
    return (chainId === CHAINID_ETH) ? ETH_CURRENCY : BSC_CURRENCY
}
export const getReverseEnsName = (chainId) => {
    return (chainId === CHAINID_ETH) ? BSC_ENS : ETH_ENS
}
export const getReverseChainId = (chainId) => {
    return (chainId === CHAINID_ETH) ? CHAINID_BSC : CHAINID_ETH
}

export const getTokenContract = (chainId) => {
    return (chainId === CHAINID_ETH) ? SKI_CONTRACT_ETH : SKI_CONTRACT_BSC
}

export const getTokenAbi = (chainId) => {
    return chainId === CHAINID_ETH ? skiTokenEthAbi : skiTokenAbi
}

export const getBridgeContract = (chainId) => {
    return (chainId === CHAINID_ETH) ? SKI_BRIDGE_CONTRACT_ETH : SKI_BRIDGE_CONTRACT_BSC
}

export const getChainName = (chainId) => {
    return chainId === CHAINID_ETH ? CHAINNAME_ETH : CHAINNAME_BSC;
}

export const getReverseChainName = (chainId) => {
    return chainId === CHAINID_ETH ? CHAINNAME_BSC : CHAINNAME_ETH;
}

export const getChainImg = (chainId) => {
    return chainId === CHAINID_ETH ? 'eth.png' : 'bsc.png';
}

export const getChainExplorer = (chainId) => {
    return chainId === CHAINID_ETH ? ETH_EXPLORER : BSC_EXPLORER;
}

export const getBridgeAbi = (chainId) => {
    return chainId === CHAINID_ETH ? skiBridgeEthAbi : skiBridgeAbi
}

export const getBridgeDeployedBlock = (chainId) => {
    return chainId === CHAINID_ETH ? ETH_BRIDGE_DEPLOYED_BLOCK : BSC_BRIDGE_DEPLOYED_BLOCK
}

export const getRpcMetamaskUrl = (chainId) => {
    return chainId === CHAINID_ETH ? ETH_METAMASK_RPC : BSC_METAMASK_RPC
}

export const getBridgeProvider = (chainId) => {
    return chainId === CHAINID_ETH ?
        new ethers.providers.AlchemyProvider(ETH_RPC)
        :
        new ethers.providers.JsonRpcProvider(BSC_RPC);
    // new ethers.providers.Web3Provider(window.ethereum)
}

export const getSkiIcon = (chainId) => {
    return chainId === CHAINID_ETH ? ETH_SKI_ICON : BSC_SKI_ICON
}

export const getSkiMetamaskIcon = (chainId) => {
    return chainId === CHAINID_ETH ? ETH_METAMASK_SKI_ICON : BSC_METAMASK__SKI_ICON
}

export const getChaincodeId = (chainCode) => {
    return chainCode === ETH_CHAINCODE ? CHAINID_ETH : CHAINID_BSC;
}

export const getChaincodeEns = (chainCode) => {
    return getEnsName(getChaincodeId(chainCode));
    // return chainCode === ETH_CHAINCODE ? ETH_ENS : BSC_ENS;
}

export const getChaincodeImg = (chainCode) => {
    return getChainImg(getChaincodeId(chainCode));
}

export const getChaincodeExplorer = (chainCode) => {
    return getChainExplorer(getChaincodeId(chainCode))
    // return chainCode === ETH_CHAINCODE ? ETH_EXPLORER : BSC_EXPLORER;
}

export const ZERO_VALUE = '0.00';
