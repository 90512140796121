import {
    Box,
    // chakra,
    Flex,
    // SimpleGrid,
    Stat,
    // StatLabel,
    // StatNumber,
    useColorModeValue,
    Image,
    // Input,
    // InputLeftAddon,
    Text,
    InputGroup,
    InputRightAddon,
    // Center,
    // Square,
    // HStack,
    // Heading,
    Spacer,
    Button,
    // Badge,
    // FormErrorMessage,
    NumberInput,
    NumberInputField
} from '@chakra-ui/react';
// import { GetAccount } from "./ConnectButton";
// import { formatEther } from "@ethersproject/units";
// import { ReactNode } from 'react';
// import { FaDeezer, FaGem, FaFunnelDollar, FaHandHoldingUsd, FaMoneyCheckAlt, FaPiggyBank, FaTicketAlt, FaWallet } from "react-icons/fa";
// import { FaPiggyBank, FaWallet } from "react-icons/fa";
// import { getConf } from '../config'
// import { userSuccess, availPoint } from "../utils/WalletUtils";
import { useSelector } from "react-redux";
import { CHAINID_ETH, ZERO_VALUE, CHAINNAME_ETH, CHAINNAME_BSC, TOKEN_NAME } from '../config'
import {
    setSwapValue
} from "../redux/actions/bridgeActions";
import { useDispatch } from "react-redux";
// import { useState } from 'react';
import * as CONF from '../config';


export default function InputValueBox(props) {
    const { title, chainId } = props;
    const chainImg = chainId === CHAINID_ETH ? 'eth.png' : 'bsc.png';
    const chainName = chainId === CHAINID_ETH ? CHAINNAME_ETH : CHAINNAME_BSC;
    const { fromChain } = useSelector((state) => state.bridge);
    const { tokenBalance, fromValue, toValue, validSwap } = useSelector((state) => state.token);
    const dispatch = useDispatch();

    // const [value, setValue] = useState('')

    // const handleChange = (event) => {
    //     const value = event.target.value;
    //     // console.log('value', value);
    //     // setValue(parseFloat(event.target.value).toFixed(2))
    //     setValue(value)
    //     dispatch(setSwapValue(value));
    // }

    const handleValue = (value) => {
        // console.log('value', value);
        // setValue(parseFloat(event.target.value).toFixed(2))
        // setValue(value)
        dispatch(setSwapValue(value));
    }

    const handleClickMax = () => {
        // setValue(tokenBalance)
        dispatch(setSwapValue(tokenBalance));
    }
    // const handleChange = (event) => setValue(event.target.value)
    return (
        <>
            <Stat
                px={{ base: 4, md: 3 }}
                py={'5'}
                shadow={'xl'}
                border={'1px solid'}
                borderColor={useColorModeValue('gray.800', 'gray.500')}
                rounded={'lg'}
                bgGradient={'linear(to-r, gray.900, gray.800)'}
                _hover={{
                    // bgGradient: 'linear(to-r, red.500, yellow.500)',
                    bgGradient: 'linear(to-r, gray.800, gray.700)',
                }}
                key={title}
            >
                <Box pl={{ base: 0, md: 0 }}>
                    <Flex pb={'3'}>
                        <Box p='0'>
                            {/* { chainId === fromChain ? 'Form:' : 'To:' } */}
                            {chainId === fromChain ? 'SKI to move from' : 'SKI you will receive to'} {chainName}
                        </Box>
                        <Spacer />

                        {
                            chainId === fromChain ?
                                <>
                                <Button
                                    size='xs'
                                    onClick={handleClickMax}
                                    >Max {tokenBalance} {TOKEN_NAME}</Button></>
                                :
                                <></>
                        }
                    </Flex>
                    <Flex w={'100%'}>
                        <InputGroup>
                            {/* <Input
                                type={'decimal'}
                                placeholder={ZERO_VALUE}
                                variant={'outline'}
                                h='1.2em'
                                fontSize={'2em'}
                                disabled={chainId === fromChain ? false : true}
                                onChange={handleChange}
                                value={chainId === fromChain ? fromValue : toValue}
                            /> */}
                            <NumberInput
                                precision={2}
                                onChange={handleValue}
                                value={chainId === fromChain ? fromValue : toValue}
                                color={validSwap === true ? 'inherit' : 'tomato'}
                            >
                            <NumberInputField
                                type={'decimal'}
                                placeholder={ ZERO_VALUE }
                                variant={'outline'}
                                h='1.2em'
                                fontSize={'2em'}
                                disabled={chainId === fromChain ? false : true}
                                // onChange={handleValue}
                                // value={ chainId === fromChain ? value : toValue }
                            />
                            </NumberInput>
                            <InputRightAddon h='1.2em' fontSize={'2em'}>
                                <Image src={chainImg} width={'22px'} borderRadius='full' border={'1px'} borderColor={'gray.400'}></Image>
                                <Image src={CONF.getSkiIcon(chainId)} width={'22px'} borderRadius='full' ml={'3px'} border={'1px'} borderColor={'gray.400'}/>
                                &nbsp;
                                <Text fontSize='md' ml={'-3px'}>{TOKEN_NAME}</Text>
                            </InputRightAddon>
                            {/* <FormErrorMessage>Email is required.</FormErrorMessage> */}
                        </InputGroup>
                    </Flex>
                </Box>
            </Stat>
        </>
    );
}