import {
  Box,
  // chakra,
  Container,
  // Stack,
  Text,
  useColorModeValue,
  // VisuallyHidden,
  // Flex,
  Link
} from '@chakra-ui/react';
// import { FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
// import { ReactNode } from 'react';
// import { COPY } from '../config'

// const SocialButton = ({
//   children,
//   label,
//   href,
// }: {
//   children: ReactNode;
//   label: string;
//   href: string;
// }) => {
//   return (
//     <chakra.button
//       bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
//       rounded={'full'}
//       w={8}
//       h={8}
//       cursor={'pointer'}
//       as={'a'}
//       href={href}
//       display={'inline-flex'}
//       alignItems={'center'}
//       justifyContent={'center'}
//       transition={'background 0.3s ease'}
//       _hover={{
//         bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
//       }}>
//       <VisuallyHidden>{label}</VisuallyHidden>
//       {children}
//     </chakra.button>
//   );
// };
interface FooterProps {
  copy: string;
  availibility: BigInt;
  user: string;
}
export default function Footer(props: FooterProps) {
  const DATE = () => {
    return new Date().getFullYear()
  }
  const COPY = '© ' + DATE() + ' Skillchain - Orange Capital SAGL'
  return (
    // <Flex
    //   bg="white"
    //   width="100%"
    //   justifyContent="center"
    //   alignItems="center"
    //   direction="column"
    //   boxShadow="0 -1px 6px -1px rgba(0, 0, 0, 0.1)"
    //   padding={4}
    // >
    //   <Flex width="100%" justifyContent="center" wrap="wrap">
    //   <Text>{COPY}</Text>
    //   </Flex>
    // </Flex>
    <Box
      bg={useColorModeValue('gray.50', 'orange.500')}
      color={useColorModeValue('gray.700', 'gray.200')}
      // pos="absolute" bottom="10%"
      //  left="0"
      textAlign={'center'}
      pos={'fixed'}
      left={0}
      bottom={0}
      right={0}
      // position: "fixed",
      //   left: 0,
      //     bottom: 0,
      //       right: 0,
      //         backgroundColor: "green"
      >
      <Container
        // as={Stack}
        maxW={'6xl'}
        py={'4px'}
        // direction={{ base: 'column', md: 'row' }}
        // spacing={4}
        // justify={{ base: 'center', md: 'space-between' }}
        // align={{ base: 'center', md: 'center' }}
        h={'48px'}
        // border={'1px'}
        // textAlign={'center'}
        >
        <Text fontSize={'0.9em'}><Link href='https://skillchain.io' target={'_blank'}>{COPY}</Link></Text>
        {/* <Text textColor={'gray.300'} fontSize={'0.7em'}>developed by <Link href='https://www.dyncode.it' target={'_blank'}>DYNcode srl</Link></Text> */}
        {/* <Text textColor={'gray.800'}>-</Text> */}
        {/* <Stack direction={'row'} spacing={6}>
          <SocialButton label={'Twitter'} href={'#'}>
            <FaTwitter />
          </SocialButton>
          <SocialButton label={'YouTube'} href={'#'}>
            <FaYoutube />
          </SocialButton>
          <SocialButton label={'Instagram'} href={'#'}>
            <FaInstagram />
          </SocialButton>
        </Stack> */}
      </Container>
    </Box>
  );
}