import { ActionTypes } from "../constants/action-types";

export const setNft = (nft) => {
  return {
    type: ActionTypes.SET_NFT,
    payload: nft,
  };
};

export const selectedNft = (nft) => {
  return {
    type: ActionTypes.SELECTED_NFT,
    payload: nft,
  };
};

export const setSwapValue = (value) => {
  return {
    type: ActionTypes.SET_SWAP_VALUE,
    payload: value,
  };
};

export const setAccount = (account) => {
  return {
    type: ActionTypes.SET_ACCOUNT,
    payload: account,
  };
};

export const setChainId = (chainId) => {
  return {
    type: ActionTypes.SET_CHAIN_ID,
    payload: chainId,
  };
};

export const setTokenContract = (tokenContract) => {
  return {
    type: ActionTypes.SET_TOKEN_CONTRACT,
    payload: tokenContract,
  };
};

export const setTokenBalance = (tokenBalance) => {
  return {
    type: ActionTypes.SET_TOKEN_BALANCE,
    payload: tokenBalance,
  };
};

export const setMarketContract = (marketContract) => {
  return {
    type: ActionTypes.SET_MARKET_CONTRACT,
    payload: marketContract,
  };
};

export const setFromBlock = (blockNumber) => {
  return {
    type: ActionTypes.SET_FROM_BLOCK,
    payload: blockNumber,
  };
};

export const setToBlock = (blockNumber) => {
  return {
    type: ActionTypes.SET_TO_BLOCK,
    payload: blockNumber,
  };
};

export const setFromRangeBlock = (blockNumber) => {
  return {
    type: ActionTypes.SET_FROM_RANGE_BLOCK,
    payload: blockNumber,
  };
}; 

export const setToRangeBlock = (blockNumber) => {
  return {
    type: ActionTypes.SET_TO_RANGE_BLOCK,
    payload: blockNumber,
  };
};

export const setSkiAvailibility = (availability) => {
  return {
    type: ActionTypes.SET_SKI_AVAILABILITY,
    payload: availability,
  };
};

export const setValidChain = (valid) => {
  return {
    type: ActionTypes.SET_VALID_CHAIN,
    payload: valid,
  };
};
