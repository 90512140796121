
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import {
    Text,
    Button,
    Spinner,
} from '@chakra-ui/react';
import * as CONF from '../config';
import { MaxUint256 } from "@ethersproject/constants";

import { useBridgeCall, useBridgeFunction, useTokenCall, useTokenFunction } from "../hooks";
import { formatEther } from "@ethersproject/units";
import { utils } from "ethers";
import { setSwapValue } from "../redux/actions/bridgeActions";

export default function SwapButton(props) {
    const dispatch = useDispatch();
    const { account, chainId, fromChain, toChain } = useSelector((state) => state.bridge);
    // console.log('chainId', chainId);
    const { fromValue, toValue, validSwap } = useSelector((state) => state.token);
    const [approving, setApproving] = useState(false);
    const [swapping, setSwapping] = useState(false);
    // const [allowance, setAllowance] = useState(false);
    // const { validSwap, toValue, fromValue } = useSelector((state) => state.token);
    const bgGradient = validSwap ? 'linear(to-l, cyan.400, red.300)' : 'linear(to-l, gray.600, gray.400)';
    const hoverBgGradient = validSwap ? 'linear(to-l, cyan.500, red.500)' : 'linear(to-l, gray.500, gray.400)';
    // console.log('CHAINID', CHAINID);
    // const [fromChain, toChain] = CHAINID;
    // console.log('fromChain', fromChain);
    // console.log('toChain', toChain);
    // const tokenAddress = chainId === CONF.CHAINID_BSC ? CONF.CHAINID_BSC : CONF.CHAINID_ETH;
    // const { title } = props;


    // const swapFee = useBridgeCall('swapFee', chainId).then(result => {console.log('swapFee result', result)});
    // useEffect(() => {
    //     const swapFee = useBridgeCall('swapFee', chainId)

    // }, [chainId]);
    const swapFee = useBridgeCall('swapFee', chainId)
    // console.log('swapFee', swapFee);
    const swapperNonce = useBridgeCall("swapperNonce", chainId, [account]);
    // console.log('swapperNonce', swapperNonce);
    // const swapper = useBridgeCall("swapper", chainId, [account, 1]);
    // console.log('swapper', swapper);
    const { state: prepareSwapState, send: setPrepareSwap } =
        useBridgeFunction("swap", chainId);
    // const { response: prepareSwapState, send: setPrepareSwap } =
    //     useBridgeFunction("prepareSwap", chainId);
    // const [response] =
    //     useTokenCall("allowance", chainId);
    // const allowance = utils.parseEther(formatEther((useTokenCall("allowance", chainId, [account, CONF.getBridgeContract(chainId)]))));
    // useEffect(() => {
    //     // setAllowance(useTokenCall("allowance", chainId, [account, CONF.getBridgeContract(chainId)]));
    //     useTokenCall("allowance", chainId, [account, CONF.getBridgeContract(chainId)])
    // }, [chainId, useTokenCall])
    
    const allowance = useTokenCall("allowance", chainId, [account, CONF.getBridgeContract(chainId)]);
    // const allowance = utils.parseEther(formatEther(useTokenCall("allowance", chainId, [account, CONF.getBridgeContract(chainId)])));
    // console.log('allowance', allowance);
    const { state: approveState, send: setApprove } =
        useTokenFunction("approve", chainId);
    

    function handlePrepareSwap() {
        // const amount = parseInt(fromValue);
        const amount = utils.parseUnits(parseFloat(fromValue).toFixed(2), "ether");
        // }
        if (amount) {
            try {
                const nonce = swapperNonce + 1;
                // console.log('nonce', nonce)
                setSwapping(true);
                // console.log('swapping', swapping);
                // console.log('amount', amount.toNumber());
                setPrepareSwap(amount, nonce, {
                    // value: utils.parseEther("0.01"),
                    value: utils.parseEther(formatEther(swapFee)),
                    // nonce
                }).then(result => {
                    dispatch(setSwapValue(''));
                    setSwapping(false);
                });
                // setSwapping(false);
                // console.log('swapping', swapping);
            } catch (e) {
                console.log('error', e);
                setSwapping(false);
            }
            // console.log('prepareSwapState', prepareSwapState)
        }
    }

    function handleApproveAmount() {
        // const amount = utils.parseEther(formatEther(parseInt(fromValue)));
        // const amount = utils.parseUnits(parseFloat(fromValue).toFixed(2), "ether");
        // console.log('amount', amount);
        // const allowance = formatEther(useTokenCall("allowance", chainId, [account, CONF.getBridgeContract(chainId)]));
        // console.log('allowance', parseInt(allowance));
        if (parseInt(allowance) <= 0) {
            try {
                setApproving(true);
                setApprove(CONF.getBridgeContract(chainId), MaxUint256).then(result => {
                    setApproving(false);
                });
            } catch (e) {
                console.log('error', e);
            }
        }
        // console.log('approveState', approveState)
        // setApproving(false);
    }
    return (
        parseFloat(toValue) > 0 ?
        <>
            {/* <Text color="white" fontSize="1xl">
                {swapFee ? formatEther(swapFee) : 0}
            </Text> */}
            <Button
                m={'6'}
                size='lg'
                width='300px'
                bgGradient={bgGradient}
                _hover={{
                    bgGradient: hoverBgGradient,
                }}
                variant='solid'
                disabled={!validSwap || approving || swapping}
                onClick={parseInt(allowance) <= 0 ? handleApproveAmount : handlePrepareSwap}
            // opacity={'30'}
                >
                    {
                        approving || swapping ?
                            approving ? 'Approving...' : 'Confirm on metamask...'
                            :
                            parseFloat(allowance) <= 0 ? 'Approve Bridge to move ' + CONF.TOKEN_NAME : 'Move ' + CONF.TOKEN_NAME + ' from ' + CONF.getEnsName(fromChain) + ' to ' + CONF.getEnsName(toChain)
                    }
                    {approving || swapping ? <Spinner ml={'10px'} /> : <></>}
                {/* {parseInt(allowance) <= 0 ? 'Approve Bridge to swap SKI' : 'Swap SKI from ' + CONF.getEnsName(fromChain) + ' to ' + CONF.getEnsName(toChain)} */}
                {/* Swap SKI from {CONF.getEnsName(fromChain)} to {CONF.getEnsName(toChain)} */}
                </Button>
                {
                    parseFloat(allowance) <= 0 ?
                        <></>
                        :
                        <Text
                            ml={'6'}
                            mt={'-6'}
                            width='300px'
                            textAlign={'center'}
                            border={'0px'}
                            fontSize={'14px'}
                        >
                                {/* Swap cost{' '} */}
                            {swapFee && formatEther(swapFee).toString()}{' '}
                            {chainId === CONF.CHAINID_ETH ? CONF.ETH_CURRENCY : CONF.BSC_CURRENCY}{' '}
                                + gas
                        </Text>
                }
        </> : <></>
    );
}