import { useSelector } from "react-redux";
import {
    // Stack,
    // Skeleton,
    Text,
    Box,
    Progress
} from '@chakra-ui/react'

const ProgressLoadingHistory = (props) => {
    const { waiting } = props;
    const { fromBlock, toBlock, fromRangeBlock } = useSelector((state) => state.bridge);
    const tot = toBlock-fromBlock;
    const complete = fromRangeBlock - fromBlock
    const perc = (complete/tot)*100;
    const remain = tot-complete;
    return (
        // remain > 0 ?
        <>
            <Box>
                { waiting === "false" ?
                <>
                        <Text fontSize={'10px'}>Searching for past transactions: remaining block {remain > 0 ? remain : '...calculating'}</Text>
                        {
                        remain > 0 ? 
                                <Progress hasStripe size='xs' value={perc > 100 ? 0 : perc} w={'335px'} colorScheme={"orange"} />
                                 : 
                                <Progress hasStripe size='xs' isIndeterminate w={'335px'} colorScheme={"orange"} />
                        }
                        {/* <Progress hasStripe size='xs' isIndeterminate w={'435px'} colorScheme={"orange"} /> */}
                        {/* <Progress hasStripe size='xs' value={perc > 100 ? 0 : perc} w={'435px'} colorScheme={"orange"} /> */}
                        {/* <Progress hasStripe size='xs' isIndeterminate w={'435px'} colorScheme={"orange"} /> */}
                </> :
                <>
                        <Text fontSize={'10px'}>Waiting blockchian scan for past transactions</Text>
                        <Progress hasStripe size='xs' isIndeterminate w={'335px'} colorScheme={"gray"} />
                </> 
                }
            </Box>
            {/* <Stack w={'435px'}>
                <Skeleton height='30px'>
                    <Progress value={80} />
                </Skeleton>
                <Skeleton height='30px' />
            </Stack> */}
        </>
        //  : <></>
    )
}

export default ProgressLoadingHistory;