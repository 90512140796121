import {
    Box,
    Flex,
    Stat,
    Code,
    Link,
    Image,
    Text,
    // Spacer,
    // Avatar
} from '@chakra-ui/react'
import { formatEther } from "@ethersproject/units";
import * as CONF from '../config';
import { ArrowRightIcon, ExternalLinkIcon } from "@chakra-ui/icons";
// import { getBridgedItem } from '../utilities';


export default function SwapHistoryBox(props) {
    const item = props.item;
    // const bridged = props.bridged;
    // const itemB = getBridgedItem(bridged, item['args'][3])
    return (
            <Stat
                px={{ base: 4, md: 3 }}
                pt={'2'}
                shadow={'xl'}
                border={'0px solid'}
                // borderColor={useColorModeValue('gray.800', 'gray.500')}
                rounded={'lg'}
                bgGradient={'linear(to-r, gray.600, gray.700)'}
                _hover={{
                    // bgGradient: 'linear(to-r, red.500, yellow.500)',
                    bgGradient: 'linear(to-r, gray.700, gray.600)',
                }}
                // key={item['transactionHash']}
        >
            <Flex pb={'3'}>
                <Image src={CONF.getChaincodeImg(item['args'][4])} width={'22px'} h={'22px'} m={'2px'} alt={'from'}></Image>
                <ArrowRightIcon w={2} h={2} color='gray' mt={'9px'} mr={'2px'} ml={'3px'} />
                <Image src={CONF.getChaincodeImg(item['args'][5])} width={'22px'} h={'22px'} m={'2px'} alt={'to'}></Image>
                {/* <Avatar src={CONF.getChaincodeImg(item['args'][4])} />
            <Avatar src={CONF.getChaincodeImg(item['args'][5])} /> */}
                <Box ml='3'>
                    <Text fontWeight='bold' fontSize={'14px'}>
                        {/* {item['event']} {formatEther(item['args'][1])} {CONF.TOKEN_NAME} */}
                        {formatEther(item['args'][1])} {CONF.TOKEN_NAME}
                        {/* - {item['args']['3']}
                        - {item['args']['amount'].toString()} */}
                        {/* - {bridged[key]} */}
                        {/* <Badge ml={'20px'} colorScheme='orange'>
                        Mined in block #{item['args']['date'].toString()}
                    </Badge> */}
                    </Text>
                    <Link fontSize='sm' target={'_blank'} href={
                        item['event'] === 'Swapped' ?
                            CONF.getChaincodeExplorer(item['args'][4]) + 'tx/' + item['transactionHash']
                            :
                            CONF.getChaincodeExplorer(item['args'][5]) + 'tx/' + item['transactionHash']
                    }>
                        <Code fontSize={'12px'}>
                            Tx: {item['transactionHash'] &&
                                `${item['transactionHash'].slice(0, 10)}...${item['transactionHash'].slice(
                                    item['transactionHash'].length - 13,
                                    item['transactionHash'].length
                                )}`}
                        </Code>
                    </Link>
                    <br />
                    <Link fontSize='sm' target={'_blank'} href={
                        item['event'] === 'Swapped' ?
                            CONF.getChaincodeExplorer(item['args'][4]) + 'token/' + CONF.SKI_CONTRACT_ETH + '?a=' + item['args'][0]
                            :
                            CONF.getChaincodeExplorer(item['args'][5]) + 'token/' + CONF.SKI_CONTRACT_BSC + '?a=' + item['args'][0]
                        // CONF.getChaincodeExplorer(item['args'][5]) + 'token/' + item['address'] + '#tokentxns'
                    }>
                        <Code fontSize={'12px'}>
                            <ExternalLinkIcon  mr={'4px'} ml={'3px'} mt={'-4px'}/>ETH balance
                        </Code>
                    </Link>{' - '}
                    <Link fontSize='sm' target={'_blank'} href={
                        item['event'] === 'Swapped' ?
                            CONF.getChaincodeExplorer(item['args'][5]) + 'token/' + CONF.SKI_CONTRACT_BSC + '?a=' + item['args'][0]
                            :
                            CONF.getChaincodeExplorer(item['args'][4]) + 'token/' + CONF.SKI_CONTRACT_ETH + '?a=' + item['args'][0]
                        // CONF.getChaincodeExplorer(item['args'][5]) + 'token/' + item['address'] + '#tokentxns'
                    }>
                        <Code fontSize={'12px'}>
                            <ExternalLinkIcon mr={'4px'} ml={'3px'} mt={'-4px'} />BSC balance
                        </Code>
                    </Link>
                    {/* https://bscscan.com/token/{CONF.SKI_CONTRACT_BSC}?a={item['args'][0]} */}
                </Box>
            </Flex>
            {/* {itemB !== undefined && 
            <Flex pb={'3'} mt={'-10px'}>
            <Box ml={'65px'}></Box>
                <Box ml='3'>
                    <Text fontWeight='bold' fontSize={'14px'}>
                        {itemB['event']} {formatEther(itemB['args'][1])} {CONF.TOKEN_NAME}
                    </Text>
                    <Link fontSize='sm' target={'_blank'} href={
                        itemB['event'] === 'Swapped' ?
                            CONF.getChaincodeExplorer(itemB['args'][4]) + 'tx/' + itemB['transactionHash']
                            :
                            CONF.getChaincodeExplorer(itemB['args'][5]) + 'tx/' + itemB['transactionHash']
                    }>
                        <Code fontSize={'12px'}>
                            TxHash: {itemB['transactionHash'] &&
                                `${itemB['transactionHash'].slice(0, 18)}...${itemB['transactionHash'].slice(
                                    itemB['transactionHash'].length - 17,
                                    itemB['transactionHash'].length
                                )}`}
                        </Code>
                    </Link>
                </Box>
            </Flex>
            } */}
            </Stat>
    )
}